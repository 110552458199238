
.banner {
	min-height: 296px;
	background: #32444F url("../img/bg/banner-default-bg.jpg") no-repeat center top;
	background-size: cover;
	box-sizing: border-box;

	// Style 1 - Dashboard

	&.banner_st1 { 
		padding: 143px 0 20px;

		@include media('<tabport') {
			display: none;
		}
	}

	// Style 2 - Promo Site

	&.banner_st2 { 
		position: relative;
		min-height: 470px;
		padding: 175px 0 90px;

		.bottom-block {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
		}

		@include media('<tabport') {
			min-height: 360px;
			margin-bottom: 1px;
			padding: 60px 0 80px;
		}

		@include media('<=phoneland') {
			padding: 40px 0;

			.bottom-block {
				display: none;
			}
		}

		.watch-download-wrap{

			.container-watch-download, .container-login-form{
				max-width: 371px;
				margin-left: auto;
				//margin-bottom: 5px;
				margin-top: 5px;
			}
			.registration-form{
				margin-left: auto;
				.container-login-form{
					//margin-top: -5px;
					margin-top: 0px;
					padding: 43px;
					@include media('<=phoneland') {
						padding: 14px;
					}
					opacity: 0.95;
					box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.36);
					background-color: $cararra-3;
				}
			}
		}
	}

	&.show-discount{
		.sign-up-discount{
			max-width: 1300px;

			.content-padding.block {
				box-sizing: border-box;
				padding: 80px 8px 0 87px;
				min-width: 250px;

				@include media("<506px"){
					padding: 0;
					text-align: center;
				}
			}
		}

		.discount {
			transform: rotate(4deg);
			text-align: -webkit-right;
			min-width: 240px;
			display: none;

			@include media("<506px"){
				text-align: -webkit-center;
			}

			.discount-up {
				height: 120px;
				width: 240px;
				border-top-left-radius: 400px;
				border-top-right-radius: 400px;
				background-color: #cdcea4;
				text-align: center;
				padding-top: 65px;

				.discount-amount {
					.discount-value {
						font-family: $alternative-font;
						font-size: 47.7px;
						font-weight: bold;
						line-height: 1.33;
						color: #31404c;
						margin-bottom: 9px;
					}
				}
			}

			.discount-down {
				height: 120px;
				width: 240px;
				border-bottom-left-radius: 400px;
				border-bottom-right-radius: 400px;
				background-color: #31404c;
				text-align: center;

				.discount-expire-date {
					font-family: $primary-font;
					font-size: 22px;
					font-weight: normal;
					line-height: 1.33;
					color: #cdcea4;
					margin-bottom: 9px;
					padding-top: 4%;

				}
			}
		}
	}

	.has-discount{
		&.discount{
			display: inline-block;
		}
	}


	// Style 3 - LOG IN

	&.banner_fullscreen {
		display: flex;
		justify-content: center;
		align-items: center;
		max-width: 100%;
		padding: 170px 0 120px;
		background-image: url("../img/bg/banner-full-bg.jpg");

		.form-container {
			flex: 0 1 auto;
		}

		@include media('<tabport') {
			padding: 187px 0 30px;
		}
	}

	.banner_landing-page{
		max-width: 550px;
		h2{
			font-family: Roboto;
			font-size: 26px;
			font-weight: 300;
			line-height: 1.31;
			color: $cararra-3;
		}
		p{
			font-family: $primary-font;
			font-size: 18px;
			font-weight: 300;
			line-height: 1.28;
			color: #e2e2e2;
			b{
				color:$white;
			}
		}

		.banner-text-black{
			h1, h2, p{
				color: #1a1a1a;
			}

		}
	}



}