
/***************** FONTS *******************/

$primary-font: 'Yantramanav', sans-serif;
$secondary-font: 'Roboto', sans-serif;
$alternative-font: 'SucroseBold', sans-serif;

/***************** COLORS *******************/

/* Color palette */

$white: #ffffff;
$white-release2:#f2f2ec;
$black: #000000;
$god-grey: #1a1a1a;
$mine-shaft: #2c2c2c;
$mine-shaft-2: #363636;
$mine-shaft-3: #252525;
$thistle-green: #cdcea8;
$cararra: #f3f3ed;
$cararra-2: #F6F6F3;
$cararra-3: #f8f8f5;
$cararra-4: #f8f8f8;
$picton-blue: #23B6EA;
$picton-blue-2: rgba(35,182,234,0.9);
$dusty-grey: #9B9B9B;
$pine-glade: #d0d09f;
$hillary: #aeae86;
$chambray: #3B5998;
$deep-cerulean: #0274B3;
$oxford-blue: #334454;
$swamp-green: #B9B987;
$mandy: #E04E4F;
$oslo-grey: #808a8e;
$oslo-grey-2: rgba(128,138,142,0.8);
$oslo-grey-3: rgba(128,138,142,0.6);
$alto: #d8d8d8;
$roman: #d65455;
$pine-glade: #cbcb93;
$pickled-bluewood: #37475d;
$vida-loca: #429321;
$wild-sand: #f4f4f4;
$astral: #3970af;
$white-rock: #eaead5;
$chamois: #ecd7b3;
$limed-spruce: #3A4650;
$limed-spruce-2: #3B4750;
$grayish-yellow: #cdcda8;
$yvory: #f5f5ef;;


// Main Colours
$accent-primary: $mine-shaft;
$accent-secondary: $thistle-green;

// Links
$link-primary: inherit;
$link-secondary: inherit;
$hover-primary: $pine-glade;
$hover-secondary: $hillary;

// Text
$body-text: $god-grey;
$heading-color: $god-grey;
$page-heading-color: $cararra;

// Default Border
$default-border: $dusty-grey;

// Social Links
$s_link-hover-bg: $picton-blue;
$s_facebook-hover-bg: $chambray;
$s_twitter-hover-bg: $s_link-hover-bg;
$s_linkedin-hover-bg: $deep-cerulean;
$s_vimeo-hover-bg: $s_link-hover-bg;

// BUTTONS
// btn_a
$a_btn-border: $mine-shaft-2;
$a_btn-text: $mine-shaft-2;
$a_btn-background: $white;

$a_btn-hover-border: $oxford-blue;
$a_btn-hover-text: $white;
$a_btn-hover-background: $oxford-blue;

// btn_b
$b_btn-border: $white;
$b_btn-text: $white;
$b_btn-background: transparent;

$b_btn-hover-border: $white;
$b_btn-hover-text: $body-text;
$b_btn-hover-background: $white;

// Submit button
$s_btn-border: $god-grey;
$s_btn-text: $god-grey;
$s_btn-background: transparent;

// Login Form
$form-header-bg: $pine-glade;
$form-body-bg: $cararra-3;
$form-title: $oxford-blue;

// Registration Form
$form2-header-bg: $thistle-green;
$form2-body-bg: $cararra-3;

// Other Forms
$input-border-bottom: $body-text;
$bar-bg: $hillary;
$input-hover: $god-grey;
$input-invalid: $mandy;

// Header
$menu-item-color: rgba(255,255,255,0.93);
$menu-item-hover-color: $hover-primary;
$mobile-menu-color: $god-grey;
$card-btn-bg: $accent-secondary;
$card-btn-hover-bg: $swamp-green;

// Footer
$footer-bg: $accent-primary;
$footer-border: $accent-secondary;

// Accordion
$accordion-title-bg: $oslo-grey;
$accordion-title-color: $white;
$accordion-opened-title-bg: $oxford-blue;
$accordion-completed-title-bg: $white-rock;
$accordion-completed-title-color: $accordion-opened-title-bg;

// Survey
$survey-title-bg: $oxford-blue;
$survey-title-color: $white;
$survey-item-bg: rgba(244,244,244,0.4);
$survey-item-border: $alto;
$survey-item-checked-bg: $white-rock;
$survey-item-checked-border: $pine-glade;
$survey-item-hover-bg: $white;
$survey-item-hover-border: $chamois;

// Pricing
$pricing-block-bg: $cararra-4;
$pricing-block2-bg: $cararra;
$pricing-block-text: $mine-shaft-2;

/********************************************/


/***************** MEDIA QUERIES**************/

$breakpoints: (
  'phoneport'	: 320px,
  'phoneland'	: 568px,
  'tabport'		: 768px, 
  'wide-tab'	: 991px, 
  'desktop'		: 1024px
);
