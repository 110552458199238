/* apply a natural box layout model to all elements */
.accordion, .accordion:before, .accordion:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.accordion {
  position: relative;
}

.accordion-item {
}

.accordion-item-open {
}

.accordion-item-open .accordion-trigger {

}

.accordion-content {
  -webkit-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
  transition: all 0.6s ease 0s;
  max-height: 0px;
  overflow: hidden;
  opacity: 0.5;
}

.accordion-item-open .accordion-content {
  -webkit-transition: all 1s ease-in 0s, opacity 0.4s ease 0.3s;
  -o-transition: all 1s ease-in 0s, opacity 0.4s ease 0.3s;
  transition: all 1s ease-in 0s, opacity 0.4s ease 0.3s;
  max-height: 1000px;
  opacity: 1;
}
