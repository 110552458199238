
.site-page.pricing {

	.pricing-section {
		.section-header {
			padding: 52px 0 15px;
			text-align: center;

			.bounds {
				max-width: 910px;
			}

			b, strong {
				font-weight: 500;
			}
		}
	}

	.registration-pricing-block-wrap{
		padding: 100px 0;
		background-size: cover;
		h1{
			font-size: 39px;
			@include media('<tabport') {
				font-size: 30px;
			}

		}
		p{
			font-size: 26px;
			font-family: $secondary-font;
		}
	}
	@include media('>=tabport') {
		.pricing-section {
			padding-bottom: 75px;

			.accordion {
				display: flex;
				justify-content: center;
				align-items: stretch;
				max-width: 984px;
				margin: 0 auto;
				padding: 0 32px;
				box-sizing: border-box;

				.accordion-item {
					position: relative;
					flex: 1 1 33.33%;
					max-width: 300px;
					width: auto;
					margin: 30px 0;
					padding: 32px 0 40px;
					background: $pricing-block-bg;
					box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.05);
					box-sizing: border-box;

					&:nth-child(2) {
						max-width: 320px;
						margin-top: 30px;
						background: $pricing-block2-bg;
						box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.2);
						z-index: 2;

						.pricing-block {
							.price-wrap {
							//	margin-bottom: 15px;
							}
							.list {
								margin-bottom: 26px;
							}
							.block-btn {
								//margin-top: 28px;
								//margin-top: 45px;
							}
						}
					}

					&:not(:nth-child(2)) {
						.block-btn {
							.btn {
								opacity: 0.6;
							}
						}
					}
				}
			}
		}
	}

	@include media('>=1200px') {
		.banner {
			.page-title {
				position: relative;
				margin-right: -30px;
			}
		}
	}

	@include media('>=tabport', '<wide-tab') {
		.pricing-section {
			.accordion {
				.content-section {
					.content-padding {
						padding: 0 16px;
					}
				}
			}
		}
	}

	@include media('<tabport') {
		.pricing-section {
			.section-header {
				padding: 30px 0 25px;
				font-size: 17px;
				line-height: 23px;
				color: $pricing-block-text;
				background: $cararra-2;
			}

			.accordion-content {
				.single-col {
					padding: 30px 32px 50px;
				}
			}
		}
	}

	@include media('<=phoneland') {
		.pricing-section {
			.accordion-content {
				.single-col {

					padding: 20px 16px 47px;
				}

				.sign {
					padding: 20px 16px 15px;
					//background-color: red;
				}
			}
		}
	}
}