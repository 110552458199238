
// Circles
.statistics-total {
	position: relative;
	margin: 0 -15px;

	.result-block {
		display: inline-flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		width: 140px;
		height: 140px;
		margin: 0 15px;
		padding: 30px 20px 48px;
		text-align: center;
		color: $white;
		border-radius: 50%;
		box-sizing: border-box;

		&:nth-child(odd) {
			background: $roman;
		}

		&:nth-child(even) {
			background: $pine-glade;
		}

		.text {
			font: 400 14px/18px $primary-font;
			text-transform: uppercase;
		}

		.result {
			font: 300 33px/24px $secondary-font;
			letter-spacing: -0.8px;
		}
	}

	@include media('<tabport') {
		margin: 0 -8px;

		.result-block {
			width: 125px;
			height: 125px;
			margin: 0 8px 10px;
			padding: 27px 17px 44px;

			.text {
				font-size: 13px;
				line-height: 16px;
			}

			.result {
				font-size: 30px;
				line-height: 21px;
				letter-spacing: -0.7px;
			}
		}
	}

	@include media('<=phoneland') {
		text-align: center;
	}
}

// 2 col grid
.statistics-wrap {
	justify-content: space-between;
	flex-wrap: nowrap;
	max-width: 100%;
	padding-top: 0;

	.statistics {
		flex: 1 1 50%;
		max-width: 485px;
		min-width: 0;
		padding-top: 0;

		.ie11 & {
			flex: 1 1 50%;
		}

		.block-title {
			margin: 0;
			font: 300 20px/30px $primary-font;
		}
	}

	@include media('<tabport') {
		.statistics {
			.block-title {
				font-size: 18px;
			}
		}
	}

	@include media('<=phoneland') {
		display: block;
		padding-top: 25px;

		.statistics {
			display: block;
			flex: none;
			max-width: none;

			.block-title {
				margin-bottom: 10px;
			}
		}
	}
}

// Statistics Style
.statistics-block {
	padding: 15px 0 30px;

	.statistics-block-item {
		margin-bottom: 12px;

		.result {
			position: relative;
			margin-bottom: -2px;
			font: 300 15px/16px $primary-font;
			text-align: right;
		}

		.scale-wrap {
			display: flex;
			align-items: center;

			.title {
				flex: none;
				width: 105px;
				margin: 0;
				padding-right: 10px;
				font: 700 15px/15px $primary-font;
				letter-spacing: normal;
				box-sizing: border-box;
			}

			.scale {
				position: relative;
				flex: 1 1 auto;
				height: 7px;
				overflow: hidden;
				background: $pickled-bluewood;
				border-radius: 4px;

				.scale-completeness {
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					width: 0;
					background: $mandy;
					background-image: linear-gradient(to bottom, #e36061, #e04e4f);
					z-index: 1;
					transition: width 0.8s ease;
				}
			}
		}
	}

	&.statistics-block_v2 {
		.statistics-block-item {
			.scale-wrap {
				.scale {
					.scale-completeness {
						background: $vida-loca;
						background-image: linear-gradient(to bottom, #b4ed50, #429321);
					}
				}
			}
		}
	}

	@include media('<=phoneland') {
		padding-bottom: 20px;

		.statistics-block-item {
			margin-bottom: 25px;

			.scale-wrap {
				display: block;

				.title {
					width: auto;
					margin-bottom: 4px;
					padding-right: 50px;
					line-height: 25px;
				}
			}

			.result {
				margin-bottom: -22px;
				z-index: -1;
			}
		}
	}
}