
.dashboard.dashboard-video-detail {

  @include media('>=tabport') {
    .main {
      padding: 0;
    }
    .top-section {
      display: none;
    }
  }

  .content-section {
    padding: 32px 0 15px;
    background-color: rgba(235, 235, 235, 0.6);

    .content-wrap {
      .title-block {
        position: relative;
        flex: 1 1 100%;
        max-width: 100%;
        min-width: 0;
        padding-bottom: 0;

        h2 {
          position: relative;
          margin-bottom: 10px;
          padding-right: 90px;
          display: inline-block;
        }
      }
    }

    .back-btn {
      top: 43px;
      right: 16px;
    }
  }

  .form-section {
    padding: 15px 0 50px;
  }

  .icon-download-summary{
    fill: #101010 !important;
    width: 15px;
    height: 15px;
    use > svg > g > path {
      fill: #101010 !important;
    }
  }

  @include media('<tabport') {
    .top-section {
      .section-header {
        position: relative;

        .back-btn {
          right: 32px;
          top: auto;
          bottom: 2px;
        }

        p {
          padding-right: 100px;
        }
      }
    }

    .content-section {
      padding: 0;
      background: transparent;

      .back-btn {
        display: none;
      }

      .content-wrap {
        flex-direction: column;

        .block {
          flex: none;
          min-width: 0;
        }

        .title-block {
          order: 1;

          h2 {
            margin: 10px 0 20px;
            padding-right: 0;
            font-size: 20px;
            line-height: 26px;
          }
        }

        .video-block {
          order: 0;
        }

        .text-block {
          order: 2;
          .icon-download-summary{
            fill: #101010;
            width: 15px;
            height: 15px;
          }
        }
      }
    }

    .form-section {
      padding: 0;
    }
  }

  @include media('<=phoneland') {
    .top-section {
      .section-header {
        .back-btn {
          right: 16px;
        }
      }
    }

    .content-section {
      .video-block {
        iframe {
          height: 200px;
        }
      }
    }
  }
}