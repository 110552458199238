
.text-tips {
	display: flex;
	flex-direction: column;

	.tip {
		flex: 1 1 25%;
		max-width: 100%;
		padding: 28px 30px;
		color: $white;
		box-sizing: border-box;

		& + .tip {
			margin-top: 4px;
		}

		&.tip1 {
			background: $pine-glade;
		}
		&.tip2 {
			background: $mandy;
		}
		&.tip3 {
			background: $oxford-blue;
		}

		h4, .tip-title, p {
			color: inherit;
		}

		h4, .tip-title {
			margin: 0 0 10px;
			text-transform: uppercase;
		}

		p {
			margin: 0;
		}
	}

	@include media('>=tabport')  {
		.tip {
			p {
				font-size: 17px;
			}
		}
	}

	@include media('<=phoneland') {
		.tip {
			padding: 22px 16px;

			h4, .tip-title {
				margin-bottom: 8px;
			}
		}
	}
}