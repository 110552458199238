.icon-add-to-favourites {
	width: 20px;
	height: 20px;
}

.icon-arrow-down {
	width: 19px;
	height: 19px;
}

.icon-arrow-right {
	width: 18px;
	height: 18px;
}

.icon-arrow-right-3 {
	width: 17px;
	height: 17px;
}

.icon-back-arrow {
	width: 19px;
	height: 19px;
}

.icon-calendar {
	width: 25px;
	height: 30px;
}

.icon-chart-line-blue {
	width: 17px;
	height: 13px;
}

.icon-chart-line-white {
	width: 12px;
	height: 10px;
}

.icon-chat {
	width: 40px;
	height: 32px;
}

.icon-check-box {
	width: 38px;
	height: 38px;
}

.icon-check-mark {
	width: 22px;
	height: 22px;
}

.icon-climb {
	width: 20px;
	height: 16px;
}

.icon-close {
	width: 36px;
	height: 36px;
}

.icon-close-gray {
	width: 16px;
	height: 16px;
}

.icon-delete {
	width: 24px;
	height: 24px;
}

.icon-desktop-monitor {
	width: 24px;
	height: 21px;
}

.icon-document {
	width: 26px;
	height: 28px;
}

.icon-download {
	width: 20px;
	height: 20px;
}

.icon-download-black {
	width: 20px;
	height: 20px;
}

.icon-drop-down-arrow-blue {
	width: 12px;
	height: 9px;
}

.icon-edit {
	width: 24px;
	height: 24px;
}

.icon-facebook {
	width: 9px;
	height: 18px;
}

.icon-heart-blue {
	width: 15px;
	height: 12px;
}

.icon-heart-white {
	width: 12px;
	height: 10px;
}

.icon-idea {
	width: 30px;
	height: 40px;
}

.icon-info {
	width: 20px;
	height: 20px;
}

.icon-light-bolt-blue {
	width: 12px;
	height: 18px;
}

.icon-light-bolt-white {
	width: 9px;
	height: 14px;
}

.icon-linked-in {
	width: 18px;
	height: 16px;
}

.icon-lock {
	width: 24px;
	height: 24px;
}

.icon-mail {
	width: 24px;
	height: 15px;
}

.icon-man {
	width: 44px;
	height: 44px;
}

.icon-minus-in-circle {
	width: 24px;
	height: 24px;
}

.icon-ondemand-video-material {
	width: 24px;
	height: 20px;
}

.icon-pdf {
	width: 24px;
	height: 24px;
}

.icon-play-button {
	width: 23px;
	height: 24px;
}

.icon-play-button-5 {
	width: 24px;
	height: 24px;
}

.icon-play-button-5-copy {
	width: 24px;
	height: 24px;
}

.icon-plus-in-circle {
	width: 24px;
	height: 24px;
}

.icon-price1 {
	width: 63px;
	height: 40px;
}

.icon-price2 {
	width: 59px;
	height: 44px;
}

.icon-price3 {
	width: 42px;
	height: 44px;
}

.icon-right-chevron {
	width: 19px;
	height: 11px;
}

.icon-self-managed {
	width: 45px;
	height: 40px;
}

.icon-shape {
	width: 65px;
	height: 53px;
}

.icon-thumbs-up-blue {
	width: 15px;
	height: 15px;
}

.icon-thumbs-up-white {
	width: 11px;
	height: 11px;
}

.icon-twitter {
	width: 17px;
	height: 14px;
}

.icon-two-men {
	width: 21px;
	height: 18px;
}

.icon-unlock {
	width: 24px;
	height: 24px;
}

.icon-user-male-black {
	width: 45px;
	height: 45px;
}

.icon-video {
	width: 24px;
	height: 24px;
}

.icon-video-player-4 {
	width: 37px;
	height: 33px;
}

.icon-vimeo {
	width: 17px;
	height: 15px;
}

.icon-your-progress-icon {
	width: 29px;
	height: 25px;
}

