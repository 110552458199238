
.btn-wrap, btn-wrap-left {
	margin: 10px 0;
	text-align: right;

	@include media('<tabport') {
		text-align: center;
	}
}
.btn-wrap-left {
	text-align: left;
	margin:  0;

}

// MAIN BUTTONS

.btn {
	position: relative;
	display: inline-block;
	min-width: 230px;
	margin: 0;
	padding: 9px 30px 7px;
	font: 400 14px/20px $secondary-font;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 7.4px;
	border-radius: 0;
	transition: all 0.25s ease-out;
	box-sizing: border-box;

	@include media('<desktop') {
		min-width: 150px;
	}

	&.btn_a {
		color: $a_btn-text;
		background: $a_btn-background;
		border: 1px solid $a_btn-border;

		&:hover {
			color: $a_btn-hover-text;
			background: $a_btn-hover-background;
			border-color: $a_btn-hover-border;
		}
	}


	&.btn_b {
		color: $b_btn-text;
		background: $b_btn-background;
		border: 1px solid $b_btn-border;
		
		&:hover {
			color: $b_btn-hover-text;
			background: $b_btn-hover-background;
			border-color: $b_btn-hover-border;
		}
	}


	&.btn_c {

		color: $a_btn-hover-text;
		background: $a_btn-hover-background;
		border-color: $a_btn-hover-border;

		&:hover {
			color: $a_btn-text;
			background: $a_btn-background;
			border: 1px solid $a_btn-border;
		}
	}

	&.submit-btn {
		padding-top: 12px;
		padding-bottom: 10px;
		color: $s_btn-text;
		background: $s_btn-background;
		border-color: $s_btn-border;
	}

	@include media('<=phoneland') {
		width: 100%;
		padding: 12px 20px;
	}
}


// NAV LINKS

.nav-link {
	position: relative;
	display: inline-block;
	margin-bottom: 10px;
	padding-right: 25px;
	font: 300 13px/23px $primary-font;
	color: $white;

	&:not(:last-child) {
		margin-right: 27px;
	}

	svg {
		top: 1px;
		right: 0;
	}
}

.back-btn {
	position: absolute;
	display: block;
	top: 0;
	right: 0;
	padding-left: 26px;
	font: 300 13px/19px $primary-font;
	color: $heading-color;

	svg {
		top: 0;
		left: 0;
	}
}

.nav-link,
.back-btn {
	letter-spacing: 0.2px;
	text-decoration: none;

	svg {
		position: absolute;
		color: inherit;
		transition: none;
	}

	&:hover {
		color: $hover-secondary;
	}
}


// OTHER BUTTONS

.bookmarkme {
	position: absolute;
	display: block;
	flex: none;
	top: 0;
	right: 0;
	padding-right: 31px;
	font-size: 12px;
	line-height: 22px;
	font-weight: 300;
	letter-spacing: 0.2px;
	text-transform: none;

	svg {
		position: absolute;
		top: 0;
		right: 0;
		opacity: 0.6;
		fill: #808a8e;
	}
}

.scrolling-down {
	padding: 10px 0;
	line-height: 0;
	text-align: center;

	.scrolling-btn {
		display: inline-block;
		padding: 5px;
		text-decoration: none;

		svg {
			display: block;
			color: $body-text;
		}

		&:hover {
			svg {
				color: $hover-secondary;
			}
		}

		&.btn_v2 {
			svg {
				color: $white;
			}

			&:hover {
				svg {
					color: $hover-primary;
				}
			}
		}
	}

	@include media('<tabport') {
		display: none;
	}
}


.group-button{
	display: flex;
	margin-top: 35px;
	a{
		margin-right:15px ;
		font-size: 17px;
		font-weight: 300;
		line-height: 1.49;
	}
	.selected{
		background-color: #3970af;
		color: $white;
		padding: 2px 10px;
	}

	.pre{
		//&::after{
		&::before{
			content: "";
			width: 9px;
			height: 11px;
			background:url("../img/resources/drop-down-arrow.png");;
			margin-right: 3px;
			display: inline-block;

		}
	}

	.next{
		//&::after{
		&::after{
			content: "";
			width: 9px;
			height: 11px;
			background:url("../img/resources/drop-down-arrow.png");;
			margin-left: 3px;
			display: inline-block;
			transform: rotate(180deg);
		}
	}
}