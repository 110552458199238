
.site-page.the-programme {
	
	.accordion-section {
		.content-section_v2 {
			.list_v2 {
				margin: 12px 0;
			}
		}
	}
}