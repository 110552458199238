
html, body {
	margin: 0;
}

body {
	font: 300 18px/23px $primary-font;
	letter-spacing: 0.3px;
	overflow-x: hidden !important;
	color: $body-text;
	background-color: $white;
	box-sizing: border-box;

	&.stickyFootWrap {
		max-width: 100% !important;
	}

	@include media('<tabport') {
		font-size: 15px;
		line-height: 1.33;
	}
}


h1, .page-title, .card-banner-title {
	margin: 30px 0 40px;
	font: normal normal 62px/1 $alternative-font;
	text-transform: uppercase;
	letter-spacing: -0.5px;
	color: $page-heading-color;

	@include media('<tabport') {
		margin: 20px 0;
		font-size: 52px;
		letter-spacing: -0.3px;
	}

	@include media('<=phoneland') {
		font-size: 36px;
	}
}

.card-banner-title {
	margin-top: 0;
	font-size: 80px;
}

.card-container {

	h1 {
		font-family: $alternative-font;
		font-size: 40px;
		//font-size: 46px;
		font-weight: bold;
		line-height: 1;
		letter-spacing: -1.04px;
		color: $mine-shaft-2;
		margin-bottom: 33px;
	}

	h2 {
		font-family: Roboto;
		font-size: 26px;
		font-weight: 300;
		line-height: 1.31;
		color: $god-grey;
		padding-bottom: 0;
	}
	.content-section_v3{
		color: $white-release2;
	}

}


h2,h3,h5,h6 {
	font-family: $secondary-font;
	color: $heading-color;
}

h2, .section-title {
	margin: 20px 0 25px;
	padding: 0 0 18px;
	font: 300 32px/34px $secondary-font;
	letter-spacing: -0.7px;
	//border-bottom: 1px solid $default-border;

	.highlighted {
		font-weight: 500;
	}

	&.section-title_v2 {
        margin: 10px 0 42px;
        padding: 0;
    	font: normal normal 46px/1 $alternative-font;
    	text-transform: uppercase;
        letter-spacing: -0.5px;
        color: $cararra;
        border: none;
	}

	@include media('<tabport') {
		font-size: 24px;
		letter-spacing: -0.5px;

        &.section-title_v2 {
            margin: 0 0 22px;
            font-size: 32px;
            letter-spacing: -0.2px;
        }
	}
}

h3 {
	margin: 30px 0;
	font: 300 24px/34px $secondary-font;
	letter-spacing: -0.5px;

	@include media('<tabport') {
		margin: 20px 0;
		font-size: 20px;
		line-height: 26px;
	}
}

h4 {
	font: bold 18px/1.2 $primary-font;
	letter-spacing: 0.3px;
	color: $mine-shaft-2;

	&.block-subtitle {
		margin: 0 0 16px;
		font-size: 20px;
		line-height: 1.15;
		color: inherit;

		@include media('<tabport') {
			padding-top: 10px;
		}
	}
	&.inline-subtitle {
		float: left;
		margin: 0 4px 0 0;
		font-size: 20px;
		line-height: inherit;
		color: inherit;

		@include media('<tabport') {
			float: none;
			margin: 0 0 16px;
			padding-top: 10px;
		}
	}
}

h5 {
	margin: 0;
	font: 700 18px/1.28 $primary-font;
	letter-spacing: 0.3px;

	&.video-title {
		margin: 0 0 26px;
		padding: 0 0 19px;
		font-weight: 400;
		border-bottom: 1px solid $default-border;

		.video-number {
			display: block;
			font-weight: 700;
			color: $oxford-blue;
		}

		@include media('<tabport') {
			margin: 0 0 24px;
			padding: 0 0 16px;
		}
	}
}

a, input, button, select, textarea {
	transition: all 0.25s ease-out;

	&, &:focus, &:hover, &:active {
		outline: 0 !important;
	}
}

a {
	text-decoration: none;
	color: $link-primary;

	&:hover {
		color: $hover-primary;
	}
}

svg {
	transition: all 0.25s ease-out;
}

ul, li {
	margin: 0;
	padding: 0;
}

img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
	width: 100%;
}

p {
	margin: 0 0 16px;
}

section {
	position: relative;
	display: block;
	width: 100%;
}

.bounds {
	//max-width: 1130px;
	max-width: 1190px;

	box-sizing: border-box;
}

.wide-bounds {
	max-width: 1330px;
	margin: 0 auto;
	box-sizing: border-box;
}

fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}

form, fieldset {
	display: block;
	width: 100%;
	box-sizing: border-box;
}

input:invalid,
select:invalid,
textarea:invalid {
	box-shadow: none;
}

input[type="text"], 
input[type="tel"], 
input[type="email"],
input[type="password"],
input[type="number"],
input[type="search"],
select, 
textarea,
label {
	margin: 0;
	font: 300 16px/20px $primary-font;
	letter-spacing: 0.3px;
	text-align: left;
	color: $body-text;
	background: transparent;
	border: none;
	border-radius: 0;
	box-sizing: border-box;

	@include media('<=phoneland') {
		font-size: 14px;
		line-height: 1;
	}
}

label {
	position: absolute;
	top: 7px;
	left: 0;
	transition: all 0.195s ease;
	pointer-events: none;
}

.bar {
	position: relative;
	display: block;
	width: 0;
	height: 2px;
	left: 0;
	bottom: 1px;
	background: $bar-bg;
	transition: all 0.195s ease;
}

input[type="text"], 
input[type="tel"], 
input[type="email"],
input[type="password"],
input[type="number"],
input[type="search"],
select, 
textarea {
	display: block;
	width: 100%;
	padding: 7px 0;
	border-bottom: 1px solid $input-border-bottom;

	@include media('<=phoneland') {
		padding: 6px 0;
	}
}

select {
	height: 35px;
	padding-right: 30px;
	background: url("../img/icons/drop-down-arrow.svg") no-repeat 98% center;
	background-position: calc(100% - 7px) center;

	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	appearance: none !important;

	&::-ms-expand {
	  display: none;
	}

	@include media('<=phoneland') {
		height: 31px;
	}
}

textarea {
	min-height: 95px;
	resize: none;
}

::-webkit-input-placeholder {color: $dusty-grey;}
::-moz-placeholder          {color: $dusty-grey;}
:-moz-placeholder           {color: $dusty-grey;}
:-ms-input-placeholder      {color: $dusty-grey;}

input:focus ~ .bar,
input:valid ~ .bar,
input:hover ~ .bar,
select:focus ~ .bar,
select:valid ~ .bar,
select:hover ~ .bar,
textarea:focus ~ .bar,
textarea:valid ~ .bar,
textarea:hover ~ .bar {
	width: 100%;
}

input:valid ~ .bar,
select:valid ~ .bar,
textarea:valid ~ .bar {
	opacity: 0;
}

input:hover ~ .bar,
select:hover ~ .bar,
textarea:hover ~ .bar {
	opacity: 1;
	background: $input-hover;
}

input:focus ~ .bar,
select:focus ~ .bar,
textarea:focus ~ .bar {
	opacity: 1;
	background: $bar-bg;
}

input:focus ~ label,
input:valid ~ label,
textarea:focus ~ label,
textarea:valid ~ label,
select:focus ~ label,
select:valid ~ label {
	top: -16px;
	font-size: 14px;
}

input[type=checkbox]:valid ~ label {
	top: 0px;
}

input:valid ~ label,
textarea:valid ~ label,
select:valid ~ label {
	color: $body-text;
}

input:focus ~ label,
select:focus ~ label,
textarea:focus ~ label {
	color: $bar-bg;
}

.form-field {
	position: relative;
	margin-bottom: 27px;

	.error-message {
		display: none;
		margin-top: 5px;
		font-size: 14px;
		line-height: 17px;
		color: $mandy;
	}

	&.validation-error {
		label {
			top: -16px;
			font-size: 14px;
			color: $mandy;
		}
		.bar  {
			width: 100%;
			opacity: 1;
			background: $mandy;
		}
		.error-message {
			display: block;
		}
	}

	&.textarea-wrap {
		padding-top: 7px;

		textarea {
			padding-top: 0;
		}
	}
}

//.form {
	.form-checkbox {
		position: relative;
		display: inline-block;
		flex: none;
		width: 22px;
		height: 22px;

		label {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			overflow: hidden;
			border: 2px solid $oxford-blue;
			border-radius: 50%;
			pointer-events: auto;
			z-index: 2;
			cursor: pointer;
			transition: all 0.25s ease;
			box-sizing: border-box;
		}

		svg {
			position: relative;
			display: block;
			width: 100%;
			height: 100%;
			color: $oxford-blue;
			z-index: 1;

			use {
				opacity: 0;
				transition: all 0.25s ease;
			}
		}

		input[type="checkbox"] {
			position: absolute;
			width: 0;
			height: 0;
			opacity: 0;
			visibility: hidden;
			z-index: -1;

			&:checked {
				& ~ svg {
					use {
						opacity: 1;
					}
				}

				& ~ label {
					border-color: transparent;
				}
			}
		}

		@include media('<=phoneland')  {
			width: 27px;
			height: 27px;
		}


	}
//}

.page-container {
	position: relative;
	width: 100%;
	box-sizing: border-box;

	@include media('<tabport') {
		margin-left: 0;
		transition: margin 0.5s ease-out;

		&::after {
			position: fixed;
			display: block;
			content: "";
			top: 0;
			left: 0;
			right: 0;
			height: 0;
			opacity: 0;
			background: rgba(54,54,54,0.54);
			transition: opacity 0.5s ease-out;
			z-index: 30;
		}

		&.menu-is-opened {
			margin-left: -270px;

			&::after {
				height: 100%;
				opacity: 1;
			}
		}
	}
}

// RANGE INPUT

.range-input-wrap {
	display: flex;
	align-items: center;

	.number {
		flex: none;
		font-size: 17px;
		line-height: 22px;
	}

	.input {
		flex: 1 1 auto;
	}
}

input[type=range] {
	 position: relative;
	 margin: 0 13px;
	 z-index: 2;
	 -webkit-appearance: none;
	 user-select: none;
}
input[type=range]:focus {
	 outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
	 width: 100%;
	 height: 8px;
	 background: #37475d;
	 border-radius: 4px;
	 animate: .2s;
	 cursor: pointer;
}
input[type=range]::-webkit-slider-thumb {
	 width: 28px;
	 height: 28px;
	 margin-top: -10px;
	 background: #eff1f4;
	 background-image: linear-gradient(to bottom, #ffffff, #eff1f4);
	 border: 1px solid $alto;
	 border-radius: 50%;
	 box-shadow: inset 0 1px 0 2px #ffffff;
	 cursor: pointer;
	 -webkit-appearance: none;
}
input[type=range]::-moz-range-track {
	 width: 100%;
	 height: 8px;
	 background: #37475d;
	 border: none;
	 border-radius: 4px;
	 animate: .2s;
	 cursor: pointer;
}
/** FF*/
input[type='range']::-moz-range-progress {
	 background-color: #e04e4f;
}
input[type=range]::-moz-range-thumb {
	 width: 28px;
	 height: 28px;
	 background: #eff1f4;
	 background-image: linear-gradient(to bottom, #ffffff, #eff1f4);
	 border: 1px solid $alto;
	 border-radius: 50%;
	 box-shadow: inset 0 1px 0 2px #ffffff;
	 cursor: pointer;
}
input[type=range]::-ms-track {
	 width: 100%;
	 height: 8px;
	 background: #37475d;
	 border-radius: 4px;
	 animate: .2s;
	 cursor: pointer;
	 color: transparent;
	 border-width: 16px 0;
	 border-color: transparent;
	 background: transparent;
	 animate: .2s;
}
input[type=range]::-ms-fill-lower {
	 border: none;
	 border-radius: 4px;
	 background: #e04e4f;
}
input[type=range]::-ms-fill-upper {
	 border: none;
	 border-radius: 4px;
	 background: #37475d;
}
input[type=range]:focus::-ms-fill-lower {
	 background: #e04e4f;
}
input[type=range]:focus::-ms-fill-upper {
	 background: #37475d;
}
input[type=range]::-ms-thumb {
	 width: 28px;
	 height: 28px;
	 background: #eff1f4;
	 background-image: linear-gradient(to bottom, #ffffff, #eff1f4);
	 border: 1px solid $alto;
	 border-radius: 50%;
	 box-shadow: inset 0 1px 0 2px #ffffff;
	 cursor: pointer;
}

/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio:0) {
	 input[type='range'] {
		 -webkit-appearance: none;
	 }
}

// MISC

.line {
	margin: 0;
	border-color: $default-border;
}


.icon-arrow-right-small{
	width: 10px;
	height: 11px;
}

.arrow-text{
	margin-right: 14px;
	padding-top: 1px;
}

.aside-list{
	//width: 100%;
	.aside-list-parent{

		display: flex;
		flex-direction: column;

		.aside-list-item{
				display: flex;
				flex-direction: row;
				justify-content: center;
				padding: 35px 45px 35px 3px;
				//padding-right: 60px;
				position: relative;


			.icon-wrap{
				margin: 0 27px;
				display: flex;
				justify-content: center;
				align-items: center;

			}

			.text-wrap{
				font-family: Roboto;
				font-size: 17px;
				font-weight: 300;
				line-height: 1.35;
				color: $cararra-3;
				//max-width: 172px;
				//max-width: 237px;
			}

			&::after {
				display: block;
				content: "";
				width: 100%;
				height: 1px;
				color: white;
				position: absolute;
				z-index: 999;
				left: 0;
				bottom:0;
				background-color: white;
			}
			&:last-child::after {
				display: none;
			}
		}



	}
}


.container-watch-download{
	display: flex;
	flex-direction: row;
	//max-width:  334px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.36);
	background-color: $cararra-3;
	.titles-wrap{
		display: flex;
		flex-direction: column;
		flex: 1 1 75%;
		background-color: $grayish-yellow;
		padding: 10px;
		.title{
			font-family: $alternative-font;
			font-size: 20.7px;
			font-weight: bold;
			line-height: 1.33;
			color: #262525;
			margin-bottom: 9px;
		}
		.subtitle{
			//font-family: Yantramanav;
			font-size: 12.6px;
			line-height: 0.64;
			letter-spacing: 0.23px;
			color: #262525;
			text-transform: uppercase;
		}
	}
	.button-wrap{
		display: flex;
		flex: 1 1 25%;
		justify-content: center;
		align-items: center;
	}
}

.flex-center{
	display: flex;
	justify-content: center;
}

.bg-white{
	color: $god-grey;
	h1 {  color: $mine-shaft-2; }
	p ,h2 { color: $god-grey; }

}


.bg-black{
	color: #f2f2ec;;
	h1 {  color: $cararra-3; }
	p ,h2 { color: $white; }

}

.book-section{
	.author-image-warp{
		text-align: center;
		margin: 34px 0;
	}

	.modal-title-wrap{
		text-align: center;
		width: 100%;
		.modal-title{
			font-family: $secondary-font;
			font-size: 26px;
			font-weight: 300;
			line-height: 1.31;
			margin: auto;
			color: $god-grey;
			max-width: 311px;

		}
	}

	.modal-time-wrap{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	    border-bottom: 1px solid $default-border;
	    border-top: 1px solid $default-border;
		align-items: center;
		padding: 10px 74px;

		select{
			border: none;
		}

		.modal-time-text{

			display: flex;
			flex-direction: row;
			//justify-content: center;
			align-items: center;

			&::before {
				display: block;
				content: "";
				width: 20px;
				height: 20px;
				background-color: #cccaff;
				border-radius: 50%;
				margin-right: 13px;
			}
	}
	}

	.select-day{
		font-size: 20px;
		font-weight: bold;
		line-height: 1;
		text-align: center;
		color: #403f3f;
		margin: 43px 0;
	}

	.carousel-days-wrap{
		display: flex;
		//flex-direction: row;
		flex-flow: row wrap;
		margin-bottom: 50px; //temporary, remove later
		padding: 0 74px;
		justify-content: center;

		.day-time-wrap-unavailable ,.day-time-wrap{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			width: 92px;
			height: 92px;
			border: solid 3px #b1b0b0;
			border-radius: 50%;
			margin: 0 15px;

			font-size: 17px;
			font-weight: 500;
			line-height: 1.18;
			text-align: center;

			.day{
				color: #403f3f;
			}

			.time{
				color: #a2a1a2;
			}


		}

		.day-time-wrap{
			border: solid 3px #31abff;
		}
		.day-time-wrap-selected{
			border: solid 3px #31abff;
			background-color: #31abff;

			&::before {
				display: block;
				content: "";
				width: 124px;
				height: 124px;
				position: absolute;
				border: solid 1px #31abff;
				border-radius: 50%;
			}

			.day, .time{
				color: $white;
			}
		}

		.day-time-wrap-unavailable{
			&::before {
				display: block;
				content: "unavailable";
				margin-top: 60px;
				position: absolute;
				font-size: 16px;
				font-weight: bold;
				line-height: 1.25;
				color: #a2a1a2;
			}

		}

		.today {
			&::after {
				display: block;
				content: "- TODAY -";
				margin-top: -70px;
				position: absolute;
				font-weight: bold;
				color: #a2a1a2;
				font-size: 17px;
				line-height: 1.18;
				color: #403f3f;
			}
		}
	}

	.btn-next-wrap{

		display: flex;
		justify-content: flex-end;
		padding: 10px 74px;
		a{
			font-size: 17px;
			font-weight: bold;
			line-height: 1.18;
			color: #31abff;

			&::after {
				display: inline-block;
				content: "";
				background-image: url("../img/icons/right-arrow-blue.svg");
				background-repeat: no-repeat;
				width: 8px;
				height: 10px;
				margin-left: 20px;

				//width: 20px;
				//height: 20px;
			}
		}
	}
}

#video-home-section-7 {


	position: absolute;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	z-index: -100;
	transform: translate(-50%, -50%);

}

/* Add some content at the bottom of the video/page */
.video-content {

	/*position: fixed;*/
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	z-index: -900;

	width: 100%;
	height: 100%;

}


@include media('<=tabport') {

	.card-banner-title {

		font-size: 60px;
	}
}

@include media('<=phoneland') {

	.card-banner-title {
		font-size: 40px;
	}
	.card-container{

		h1{
			font-size: 30px;
		}
		h2{
			font-size: 20px;
		}
	}


}




//tooltip

.tooltip-wrap{
	//width: 100%;
	text-align: right;
	display: inline-block;
}
.tooltip {
	position: relative;
	display: inline-block;
	text-align: right;

}

.tooltip .tooltiptext {
	visibility: hidden;
	width: 264px;
	background-color: #344554;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 10px;
	position: absolute;
	z-index: 1;
	bottom: 125%;
	/*left: 50%;*/
	/*left: 75%;*/
	/*margin-left: -60px;*/
	margin-left: -60px;
	opacity: 0;
	transition: opacity 0.3s;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	z-index: 99999;

	/*1275*/
}

.tooltip-v2 .tooltiptext {
	width: 172px;
}


.tooltip .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 20%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #344554 transparent transparent transparent;
}

.tooltip-v2 .tooltiptext::after {
	margin-left: 10px;
}


.tooltip:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}


.tooltip-left .tooltiptext{
	margin-left: -264px;
}

.tooltip-left .tooltiptext::after{
	left: 95%;
}


@media only screen and (max-width: 1275px) {

	//#slideMenu3{
	.tooltip .tooltiptext{
		margin-left: -264px;
	}

	.tooltip .tooltiptext::after {
		left: 95%;
	}


	.tooltip-v2 .tooltiptext{
		margin-left: -175px;
	}

	.tooltip-v2 .tooltiptext::after{
		left: 86%;
	}


	.tooltip-start .tooltiptext{
		margin-left: -60px;
	}

	.tooltip-start .tooltiptext::after {
		left: 20%;
	}

}


.additional-block {
	display: flex;
	align-items: center;

	.form-checkbox {

	}
	.small-text{
		font-size: 14px;
		line-height: 1.29;
		letter-spacing: 0.26px;
		color:$god-grey;
		padding-right:10px ;
	}

	.add-user-text{
		font-size: 16px;
		margin-left: 8px;
	}
}

.line{
	height: 1px;
	background-color: $god-grey;
	margin: 20px 0;
}

.become-mojo-champion{

	display: flex;
	align-items: center;
	span{

	}
}

.key-change-form{
	margin: 16px 0;
	display: flex;
}
.key-change-field{
	margin: 0 20px;
	width:unset;
}


.title-container-thought-for-day{
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 20px;
	.title, .title-day{
		font-size: 24px;
		font-weight: 300;
		line-height: 1.42;
		letter-spacing: -0.54px;
		color: #1a1a1a;
		margin: 0;
	}
	.title-day{
		margin-left: 5px;
	}
}

.text-thought-for-day{
	font-size: 18px;
	font-weight: 300;
	line-height: 1.28;
	color: #1a1a1a;
}
.badge-red{
	//width: 23px;
	//height: 23px;
	//background-color: #d65555;
	//display: flex;
	//justify-content: center;
	//align-items: center;
	//border-radius: 50%;

	border-radius: 50%;
	background-color: #d65555;
	text-align: center;
	width: 23px;
	height: 23px;
	display: inline-block;

	span{
		font-size: 14px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.65;
		letter-spacing: -0.32px;
		text-align: center;
		color: $white;
		padding-left: 1px;
		margin-top: -1;
	}
}

.title-box-thought-of-day{
	margin-bottom: 20px;
	div{
		font-size: 18px;
		line-height: 1.28;
		letter-spacing: 0.3px;
		color: $god-grey;
		font-weight: 400;
	}
	.title-box-day{
		font-weight: bold;
		color: #393b41;

	}
}

.step-marker-title{
	display: flex;
	justify-content: flex-end;
	h2{
		margin: 0;
		padding: 0;
	}
	.step-marker{
		display: flex;
		.badge-red{
			margin-left: 3px;
		}
		span{
			margin: 0 4px;
		}
	}
}

.step-step-marker-v2{

	padding-bottom: 10px;
	.step-marker{
		margin-top: 5px
	}
}
.align-item-center{
	align-items: center;
}


.container-watch-today{
	margin-top: 45px;
}


.register-account-tutorial-content{

	margin-top: 40px;

	p{
		margin-top: 40px;
	}
}


