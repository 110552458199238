
.dashboard.dashboard-home {

  .under-header {
    .small-separator {
      max-width: 100%;
      height: 6px;
      background-color: #caca89;
      margin: auto;
      position: relative;
    }

    .under-header-content {
      max-width: 100%;
      background-color: #f8f8f5;
      margin: auto;
      position: relative;

      .main-under-header-content {
        justify-content: space-between;
        align-content: center;
        margin: auto;
        max-width: 1189px;
        text-align: left;

        .under-header-title {
          font-family: Roboto, sans-serif;
          font-size: 20px;
          font-weight: 300;
          line-height: 1.7;
          letter-spacing: -0.45px;
          color: #1a1a1a;
          display: flex;
          flex-direction: row;
          align-items: center;

          span {
            margin-left: 10px;
          }

        }

        .under-header-summary {
          font-family: Yantramanav, sans-serif;
          font-size: 15px;
          font-weight: 300;
          line-height: 1.33;
          letter-spacing: normal;
          color: #1a1a1a;

          .lower-case-text {
            text-transform: lowercase;
            font-weight: 400;
          }


        }

        .block {
          max-width: 330px;
          min-width: 200px;
        }

        .action-link{
          font-family: Yantramanav, sans-serif;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.44;
          letter-spacing: normal;
          color: #3970af;
          padding-top: 10px;

          button {
            border: none;
            background-color: unset;
            padding: 0;

            font-family: Yantramanav, sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 1.44;
            letter-spacing: normal;
            color: #3970af;
          }
        }

      }
    }

  }

  .main {
    padding-bottom: 0;
  }

  // Sections

  .video-section {
    padding: 0 0 50px;

    .block-title {
      margin: 30px 0 0;

      @include media('<tabport') {
        margin: 24px 0 14px;
      }
    }

    .content-wrap {
      flex-wrap: nowrap;

      .video-title {
        max-width: 377px;
      }

      .btn-wrap {
        margin: 10px 0;
      }

      @include media('<tabport') {
        flex-direction: column;
        padding-top: 0;
        padding-bottom: 0;

        .block {
          display: block;
          flex: 0 1 auto;
          padding-top: 10px;
          padding-bottom: 10px;
        }

        .left-block {
          order: 2;
        }

        .right-block {
          order: 1;
        }
      }

      @include media('<=phoneland') {
        iframe {
          height: 200px;
        }
      }
    }


  }

  .statistics-section {
    h3 {
      margin: 30px 0 20px;
    }

    .section-header {
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      padding-bottom: 0;

      .block {
        flex: 0 1 auto;
        min-width: 0;
        padding-bottom: 5px;
      }
    }

    @include media('<=phoneland') {
      h3 {
        margin: 24px 0 20px;
      }

      .section-header {
        display: block;

        .block {
          display: block;
        }
      }
    }
  }

  .content-section {
    padding: 40px 0 55px;

    h3 {
      margin: 30px 0 18px;
    }

    .btn-wrap {
      margin-top: 30px;
    }

    @include media('<tabport') {
      padding: 20px 0 45px;

      h3 {
        margin: 22px 0 26px;
      }

      .btn-wrap {
        margin-top: 37px;
      }

      .flex-parent,
      .block {
        display: block;
        min-width: 0;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  .table-section {
    padding-bottom: 0px;
  }

  @include media('<wide-tab') {
    .table-section {
      padding-bottom: 70px;
     // background: $wild-sand;
    }
  }

  @include media('<tabport') {
    .table-section {
      padding-bottom: 25px;
    }
  }


  .table-section-v3 {

    table{
      border: none;
      width: 100%;


      tr{
        border-bottom: solid 0.5px gray;

        th{
          border: none;
          font-size: 16px;
          &:first-child{
            border-right: solid 0.5px gray;
          }
        }

        td{
          font-size: 16px;
          font-weight: 300;
          line-height: 1.06;
          letter-spacing: 0.3px;
          color: #1a1a1a;

          &:first-child{
            border-right: solid 0.5px gray;
            border-left: none;
          }
          &:nth-child(2n) {
            background-color: $wild-sand;
          }
        }

      }

    }

  }


  .wrap-select{
    display: flex;
    margin-right: 17px;


    .btn-select{
      padding: 0px 13px 0px;
      border: solid 1px #363636;

      background-color: #f5f5ef;
      height: 37px;
      min-width: 150px;
      &:hover{
        color: #334454;
      }
      &::after{
        content: "233";
        color: red;
        font-weight: bold;
      }

    }


    button {
      border-left: none;
      display: flex;
      justify-content: center;
      align-items: center;
      border: solid 1px #363636;
      border-left: none;
      width: 45px;
      height: 37px;
      background-color: #f5f5ef;
      cursor: pointer;
    }
  }


  .btn-change{
    min-width: 150px;
  }

  .table-team-member{
    .table_v1-wrap{
      border:none;
    }
    th{
      border-bottom: solid 0.5px #9b9b9b;
    }
  }

  table_v1{
    border:none;
    th{
      border-bottom: solid 0.5px #9b9b9b;
    }
  }
  .block-subtitle{

    padding-bottom: 29px;
    margin: 0;

  }


  .header-title-inline{
    padding: 0;
    margin: 0 10px 0 0;
  }

  .table-section-previous-surveys{
    margin-top: 20px;
  }

  .title-container-thought-for-day{
    display: inline-block;
    //.title{
    //  margin-left: 10px;
    //}
  }

  .image-summary {
    text-align: end;
  }

  .favorite-videos-section {
    .bounds {
      .favorite-title {
        .favorite-title1 {
          font-family: $secondary-font;
          font-size: 24px;
          font-weight: bold;
          line-height: 1.42;
          letter-spacing: -0.54px;
          color: #1a1a1a;
          .favorite-title2 {
            font-weight: 300;
          }
        }
      }
    }
  }

}