
.site-page {


	.resources-grid{

		.resources-block{
			flex: 1 1 30%;
			max-width: 33.33%;
			padding: 8px;
			margin-top: 60px;

			.image-wrap{
				margin-bottom: 35px;
			}

			.article-heading{
				font-family: Yantramanav;
				font-size: 15px;
				display: flex;
				flex-flow: row;
				justify-content: space-between;
				margin-bottom: 3px;
				.article-category{

					font-weight: bold;
					line-height: 1.33;
					color: #20262a;
					text-align: left;
				}

				.article-date{

					font-weight: 300;
					line-height: 1.33;
					text-align: right;
					color: #7b7a7a;

				}
			}
			hr{
				margin-top: 0;
			}
			.article-title{
				font-family: Roboto;
				font-size: 23px;
				font-weight: 300;
				color: #3c3c3b;
				margin: 10px 0;
			}
			.article-author{
				font-family: Yantramanav;
				font-size: 15px;
				font-weight: 300;
				line-height: 1.33;
				color: #20262a;
				span{
					font-weight: 500;
				}
				margin: 10px 0;
			}

			.article-summary{
				font-family: Yantramanav;
				font-size: 16px;
				font-weight: 300;
				color: #000000;
			}

			.a-readmore{
				display: flex;
				flex-flow: row;
				align-items: center;
				.article-readmore{
					font-family: Roboto;
					font-size: 12px;
					line-height: 2.33;
					letter-spacing: 4px;
					color: $mine-shaft-2;
					margin-right: 4px;

					.icon-arrow-right {
						width: 10px!important;
						height: 11px!important;
					}
				}
			}
		}

		.resources-block:nth-child(even):nth-child(3n+1):before {
			display: block;
			background-color: $cararra-3;
			content: "";
			position: absolute;
			width: 100%;
			left: 0;
			//height: 33.3%;
			z-index: -1;
			margin-top: -50px;

		}
	}


	// Isotope

	.isotope-section {
		padding: 45px 0 25px;
		overflow: hidden;

		.isotope-control {
			.block-text {
				padding-bottom: 16px;
				border-bottom: 1px solid gray;
			}
			.filter-button-group {
				padding: 18px 0 22px;
			}
			.filter {
				display: inline-block;
				margin-right: 55px;
				font-size: 20px;
				//font-weight: $semibold;
				color: gray;
				transition: color 0.25s ease-out;
				cursor: pointer;

				&:last-child {
					margin-right: 0;
				}
				&:hover {
					color: tomato;
				}
				&.active {
					color: red;
				}
			}
		}


		.header-isotope-control {
			width: 100%;
			height: 63px;
			background-color: #f2f2ea;
			position: absolute;
			top: 0;
			left: 0;

			display: flex;
			flex-flow: row;
			align-items: center;
			justify-content: flex-end;

			.filter-by{
				//margin-right: 20px;
				font-family: Yantramanav;
				font-size: 12px;
				font-weight: 300;
				letter-spacing: 2.18px;
				color: #000000;
				padding-left: 15px;
			}
			.btn-open-categories{
				display: inline;
				width: auto;
				text-align: center;
				height: 100%;
				font-family: Yantramanav;
				font-size: 15px;
				font-weight: 500;
				color: #000000;
				margin: 0 0 0 20px;
				background-color: $white;
				padding: 0 35px;
				border:none;
			}

			.reload{
				width: 125px;
				padding-left: 21px;

			}

			.element-container{
				max-width: 1330px;
				margin: auto;
				width: 100%;
				text-align: right;
				height: 100%;
			}
		}

		.isotope-control{

			min-width: 100%;
			position: absolute;
			top: 64px;
			left: 0;
			background-color: $white;
			//padding: 46px 0px;
			padding: 56px 0px;
			display: none;
			-moz-box-shadow:  0 4px 4px 0 rgba(0, 0, 0, 0.2);
			-webkit-box-shadow:  0 4px 4px 0 rgba(0, 0, 0, 0.2);
			box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);


			.filter-button-group{
				padding: 0 0 0 24px;
				//max-width: 80%;
				//display: inline-block;
				width: 100%;
				display: flex;
				flex-direction: row;
				flex-flow: wrap;
				cursor: pointer;

				@include media('<=phoneland') {
					flex-flow: column;
				}


				.filter{
					font-family: Yantramanav;
					font-size: 30px;
					font-weight: 300;
					line-height: 1.25;
					color: #3c3c3b;
					//margin-right: 84px;
					//width: 32%;
					//width: 100%;
					margin-right: 0px;
					margin-top: 13px;


				}


				.active{
					color: $grayish-yellow;
					&:before {
						display: block;
						background-color: $cararra-3;
						content: "";
						position: absolute;
						background-image: url("./../img/icons/tick-sign.svg");
						width: 19px;
						height: 16px;
						margin-left: -30px;
						margin-top: 8px;
						background-color: transparent;

						//width: 100%;
						//left: 0px;
						//height: 33.3%;
						//z-index: -1;
						//margin-top: -50px;

					}

				}

				@media only screen and (max-width: 1100px) {

						padding-left: 40px;
						.filter {
							font-size: 20px;
						}


				}
			}



			.wrap-btn-select{
				width:18%;
				display: inline-block;
				text-align: center;
				position: relative;
				float: right;
				button{
					width: 139px;
					height: 59.4px;
					border-radius: 2.2px;
					background-color: #cdcda8;
					text-transform: uppercase;
					//margin-top: 40px;
					position: absolute;

					font-family: Roboto;
					font-size: 11px;
					font-weight: 500;
					letter-spacing: 4px;
					text-align: center;
					color: #000000;
					margin-top: -3px;
					left: 30px;

				}
				@media only screen and (max-width: 1100px) {

					button{
						margin-top: -6px;
					}
				}


			}

		}

		@include media('<tabport') {
			padding-bottom: 5px;

			.resources-block {
				flex: 1 1 50%;
				max-width: 50%;
			}

			 .isotope-control {
				 .filter-button-group{
				 	max-width: 100%;
				}
				 .wrap-btn-select {
					 width: 100%;

					 button {
						 right: 16px;
						 left: auto;
						 margin-top: 6px;
						 height: 45px;
					 }

				 }
			 }

		}
		@include media('<=phoneland') {
			.isotope-control {
				.filter {
					margin-right: 20px;
					font-size: 18px;
				}
			}

			.resources-block {
				flex: 1 1 100%;
				max-width: 100%;
				.image-wrap{
					text-align: center;
				}
			}


			.isotope-control {
				.filter-button-group{
					width: 100%;
					text-align: center;
					margin: auto;
					.filter{
						display: block;
						width: 100%;
						text-align: center;
						margin: 10px auto;
					}
				}
				.wrap-btn-select {
					width: 100%;

					button {
						right: 16px;
						left: auto;
						margin-top: 6px;
						height: 45px;
					}

				}
			}
		}

	}




	.resources-detail-wrap {

      .block-info {
        -webkit-flex: 1 1 67%;

        .header {

          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-family: Yantramanav;
          font-size: 17px;
          line-height: 1.18;

          .category {
            font-weight: bold;
            color: #20262a;
          }

          .date {
            text-align: right;
            color: #7b7a7a;
          }
        }

        .title {
          font-family: Roboto;
          font-size: 36px;
          font-weight: 300;
          color: #3c3c3b
        }

        .author-wrap {
          font-family: Yantramanav;
          font-size: 18px;
          font-weight: 300;
          line-height: 1.28;
          color: $god-grey;

          span {
            font-weight: 500;
          }
        }

        p {
        font-family: Yantramanav;
        font-size: 18px;
        font-weight: 300;
        line-height: 1.28;
        color: $god-grey;
      }

        blockquote{
          margin: 30px 0 0 0 ;
          font-family: Roboto;
          font-size: 17px;
          font-weight: 300;
          font-style: italic;
          color: #272727;
          position: relative;

          &:before {
            display: block;
            width: 44px;
            height: 38px;
            content: "";
            background-image: url("./../img/icons/blockquote.svg");
            margin-bottom: 41px;
          }

          &:after {
            display: block;
            width: 100%;
            height: 1px;
            background-color: #cecbcb;
            content: "";
            position: absolute;
            top: 0;
            margin-top: 59px;

          }
        }

		  .shop-section-wrap{
			  .flex-parent{
				 justify-content: space-between;
				  padding: 0;
			  .block{
				  -webkit-flex: 1 1 49%;
				  max-width: 48%;
				  padding: 0;

				  .article{
					  img{
						  margin-bottom: 30px;
					  }
					  .article-title{
						  font-family: Roboto;
						  font-size: 18px;
						  font-weight: 500;
						  color: #344554;
						  margin-bottom: 15px;
					  }
					  .article-description{
						  font-family: Yantramanav;
						  font-size: 16px;
						  font-weight: 300;
						  color: #000000;
					  }
					  .article-price{
						  font-family: Roboto;
						  font-size: 18px;
						  font-weight: 500;
						  color: #344554;
						  margin-bottom: 21px;
					  }

					  .btn-buy{
						  width: 100%;
						  height: 38px;
						  border: solid 1px $mine-shaft-2;
						  display: flex;
						  align-items: center;
						  justify-content: center;

					  }
				  }

				  @include media('<=phoneland') {
					  max-width: 100%;
					  margin-bottom: 30px;
				  }
			  }
			  }
		  }
    }

		.aside-block{
			-webkit-flex: 1 1 33%;

			.aside-author-wrap{
				width: 100%;
				background-color: $yvory;
				padding: 27px;

				p {
					font-family: Yantramanav;
				}
                .author-text{

                    font-size: 17px;
                    font-weight: bold;
                    line-height: 1.18;
                    color: #20262a;
                    margin-top: 25px;
                    margin-bottom: 7px;
                }
                hr{
                    margin-top: 0px;
                }
                .author-name{
                    font-size: 32px;
                    font-weight: 300;
                    line-height: 1.25;
                    color: #3c3c3b;
                    margin-bottom: 50px;
                }

                .author-phone{
                    font-size: 18px;
                    font-weight: 300;
                    line-height: 1.28;
                    color: $god-grey;
                }

                .author-email{
                    font-size: 17px;
                    line-height: 1.35;
                    letter-spacing: 0.3px;
                    color: #3970af;
                }


			}

				.aside-download-wrap{

					margin-top: 30px;

					.download-wrap-header{
						padding:0 27px;
						width: 100%;
						height: 59px;
						background-color: #344554;
						font-family: Yantramanav;
						font-size: 17px;
						font-weight: bold;
						line-height: 1.18;
						color: #ffffff;
						display: flex;
						align-items: center;

					}

					.download-wrap-body{
						padding: 27px;
						background-color: $yvory;
						p{
							font-family: Yantramanav;
							font-size: 17px;
							font-weight: 300;
							line-height: 1.28;
							color: $god-grey;
						}



						.file-info-wrap{
							display: flex;
							flex-direction: row;
                            margin-bottom: 20px;

                          .download-icon{
                              margin-right: 10px;
                          }
                              .file-info{
                                  font-family: Yantramanav;
                                  font-size: 17px;
                                  line-height: 1.35;
                                  letter-spacing: 0.3px;
                                  color: #3970af;
								  overflow: auto;
								  word-wrap: break-word;
                              }

                          }
                      }

				}


		}

	}


	@include media('<tabport') {

		.resources-detail-wrap {
			.aside-block {
				.aside-author-wrap {
					padding: 10px;

					.author-name{
						font-size: 20px;
						margin-bottom: 20px;
					}
				}

			.aside-download-wrap {

				.download-wrap-header {
					padding: 0 10px;
					text-align: center;
				}

				.download-wrap-body {
					padding:  10px;
				}
			}
			}
		}
	}

	@include media('<=phoneland') {

		.resources-detail-wrap {
			.aside-block {
				.aside-author-wrap {
					padding: 25px;
					text-align: center;


				}


			}
		}
	}



	// for test


	.main_content {
		&.project {
			background-color: yellow;
			.content {
				padding-top: 35px;
			}
		}
	}

	.projects_filter {
		//margin-top: 110px;
		//margin-bottom: 30px;
	}

	.projects_filter_form {
		position: relative;
		text-align: left;
	}

	.projects_filter_row {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		margin-left: -20px;
		margin-right: -20px;
	}
	.projects_filter_col {
		width: 100%;
		padding-left: 20px;
		padding-right: 20px;
	}

	.projects_filter_items {
		display: flex;
		align-items: stretch;
		flex-wrap: wrap;
		margin-bottom: 25px;
	}

	.projects_filter_items_row {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: stretch;
		margin-left: -10px;
		margin-right: -10px;
	}

	.projects_filter_item {
		position: relative;
		//width: 16.6%;
		width: 32%;
		//padding-left: 10px;
		//padding-right: 10px;
		//margin-bottom: 25px;
		//text-align: center;
		//margin-right: 0px;
		padding: 16px 0;
		margin: 8px 0;
		vertical-align: center;
		display: flex;
		/* height: 100%; */
		@include media('<=phoneland') {
			width: 90%;
		}
	}

	.projects_filter_col_project {
		width: 50%;
		padding-left: 20px;
		padding-right: 20px;
	}

	.projects_filter_items_row_project {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: stretch;
		margin-left: -10px;
		margin-right: -10px;
	}

	.projects_filter_item_project {
		position: relative;
		width: 33.3%;
		padding-left: 10px;
		padding-right: 10px;
		margin-bottom: 25px;
		text-align: center;
	}
	.projects_filter_input {
		//position: absolute;
		position: relative;
		left: 0;
		top: 0;
		z-index: -111;
		width: 0;
		height: 0;
		visibility: hidden;
		opacity: 0;
		&:checked {
			&+ .projects_filter_input_label {
				background-color: #141c2e;
				color: $white;
			}
		}
	}
	.projects_filter_input_label {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		background-color: $white;
		padding: 24px 10px;
		width: 100%;
		line-height: 1.2;
		font-size: 22px;
		height: 100%;
		//min-height: 100px;
	}

    #filtered-results{
      min-height: 200px;
    }


}
