
// MODAL STYLES

.mojo-modal.modal-wrap {
	overflow: hidden;
	visibility: hidden;
	z-index: -100;

	.modal-close {
		position: absolute;
		top: 16px;
		right: 22px;
		width: 36px;
		height: 36px;
		line-height: 0;
		background: transparent;
		border: none;
		border-radius: 0;

		svg {
			display: block;
			width: 36px;
			height: 36px;
			color: $white;
		}
	}

	.modal-overlay {
		padding: 110px 0;
	//	transition: all 0.6s ease-out 0.6s;
		box-sizing: border-box;
	}

	.modal-contentWrap {
		text-align: left;
		box-sizing: border-box;

		.modal-header {
			min-height: 290px;
			padding: 47px 0 15px;
			opacity: 0;
			background: #32444F no-repeat center top;
			background-size: cover;
			transition: opacity 0.3s ease 0s;
			box-sizing: border-box;
			z-index: -1;

			.header-logo {
				max-width: 1162px;
				margin: 0 auto;
				padding-bottom: 20px;
				font-size: 0;
				line-height: 0;
				box-sizing: border-box;

				a {
					display: inline-block;

					img {
						display: block;
					}
				}
			}

			.header-content {
				max-width: 1090px;
				margin: 0 auto;
				box-sizing: border-box;
			}
		}

		.modal-content {
			padding: 65px 0;
			background: $white;
		}
	}

	// ACTIVE

	&.active {
		visibility: visible;
		z-index: 9000;

		.modal-overlay {
			background-color: rgba(44,44,44,0.91);
		//	transition: all 0.5s ease 0s;
		}

		.modal-contentWrap {
			position: relative;
			max-height: 100%;
			max-width: 1268px;
			width: 90%;
			width: calc(100% - 64px);
			margin: 0 auto;
			padding: 0;
			background: $white;
			border: none;

			.modal-header {
				opacity: 1;
				transition: opacity 0.5s ease 1s;
				z-index: 1;
			}
		}
	}

	@include media('<1200px') {
		.modal-overlay {
			padding: 50px 0;
		}
	}

	@include media('<tabport') {
		.modal-overlay {
			padding: 32px 0;
		}

		.modal-close {
			top: 3px;
			right: 3px;
			width: 32px;
			height: 32px;

			svg {
				width: 32px;
				height: 32px;
			}
		}

		.modal-contentWrap {
			.modal-header {
				min-height: 0;
				padding: 100px 0 35px;

				.header-logo {
					display: none;				
				}
			}

			.modal-content {
				&, .content-padding {
					padding: 0;
				}
			}
		}
	}

	@include media('<=phoneland') {
		.modal-overlay {
			padding: 20px 0;
		}

		&.active {
			.modal-contentWrap {
				width: calc(100% - 32px);
			}
		}
	}


	// FAQ's MODAL

	&.faq-modal {
		@include media('>=1200px') {
			.modal-contentWrap {
				.modal-content {
					padding: 75px 0 170px;
				}
			}
		}
	}
}