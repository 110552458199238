
.page-header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	min-height: 130px;
	color: $white;
	z-index: 50;

	@include media('<tabport') {
		position: relative;
		min-height: 0;
		background: $accent-primary;
	}

	.site-nav {
		position: relative;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 46px 300px 10px 16px;

		@include media('<1200px') {
			padding-right: 220px;
		}
		@include media('<tabport') {
			justify-content: space-between;
			padding: 15px 32px;
		}
		@include media('<=phoneland') {
			padding: 15px 16px;
		}

		.logo {
			margin-right: 60px;
			opacity: 0.93;

			@include media('<desktop') {
				margin-right: 40px;
			}
			@include media('<tabport') {
				margin-right: 25px;
				padding: 0;

				img {
					max-width: 84px;
					width: 100%;
				}
			}
		}

		.main-menu-wrap {

			flex: 1 1 auto;
			@include media('<tabport') {
				position: relative;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				margin-top: -8px;
				margin-right: -4px;
			}

			.logout-btn {
				display: inline-block;
				padding: 2px 0 1px;
				font-size: 10px;
				line-height: 1;
				text-transform: uppercase;
				color: $white;

				&:hover {
					text-decoration: none;
					color: $menu-item-hover-color;
				}
			}
		}

		.main-menu-login {
			@include media('>phoneland') {
				display: none;
			}
		}

		.main-menu {
			position: relative;
			list-style: none;
			flex: 1 1 auto;
			//background-color: red;

			li {
				position: relative;
				line-height: 0;
				transition: none;
				flex: 1 1 auto;


				a {
					display: inline;
					padding: 0;
					font: 700 15px/20px $primary-font;
					text-transform: uppercase;
					text-decoration: none;
					color: $menu-item-color;

					@include media('>phoneland') {
						&:hover {
							color: $menu-item-hover-color;
						}
					}
				}



				//&.on {
				//	&::before {
				//		position: absolute;
				//		display: block;
				//		content: "";
				//		top: 50%;
				//		left: 20px;
				//		width: 9px;
				//		height: 10px;
				//		margin-top: -5px;
				//		background: url("../img/icons/arrow-right.svg") no-repeat center center;
				//		background-size: cover;
				//	}
				//
				//	a {
				//		pointer-events: none;
				//
				//		&:hover {
				//			color: $menu-item-color;
				//			cursor: default;
				//		}
				//	}
				//}
			}


			.first-level-submenu {
				a{
					display: inline;
					padding: 0;

					font-size: 18px;
					font-weight: 300;
					line-height: 1;
					letter-spacing: 0.34px;
					//color: #a2a1a2;
					color: #d8d7d8;
					text-transform: none;
					text-decoration: none;
					//color: $menu-item-color;
					white-space: nowrap;

					&:hover {
						color:  $menu-item-color;
					}

				}

				li{
					margin: 10px 0;
				}

			}

			@include media('<=tabport') {

				.second-level-submenu  li:first-child{
					margin-top: 0;
				}
			}

			.first-level-submenu, .second-level-submenu {
				width: 100%;
			}

			@include media('>=tabport') {
				li {
					&.mobile-menu-item {
						display: none !important;
						margin: 0;
					}
				}
			}

			@include media('<1200px') {
				li {
					//padding: 0 20px;

					padding-right: 10px;

					&.on {
						&::before {
							left: 0;
						}
					}
				}
			}

			@include media('<desktop') {
				height: 84px;
			}

			@include media('<tabport') {
				position: fixed;
				display: block;
				flex: none;
				top: 0;
				right: 0;
				bottom: 0;
				width: 270px;
				height: auto;
				min-width: 0;
				margin-top: 0;
				margin-right: -270px;
				//padding: 70px 32px 30px;
				padding: 70px 16px 30px;
				overflow-x: hidden;
				overflow-y: scroll;
				background: $cararra-2;
				transition: margin 0.5s ease-out;
				z-index: 100;
				-webkit-overflow-scrolling: touch;
				overflow-scrolling: touch;
				box-sizing: border-box;

				li {
					display: block;
					float: none;
					max-height: none;
					width: auto;
					margin: 0;
					padding: 0 10px;

					a {
						display: block;
						padding: 8px 5px;
						color: $mobile-menu-color;
					}

					&.on {
						&::before {
							margin-top: -6px;
							background-image: url("../img/icons/arrow-right-dark.svg");
						}
					}
				}

				&.is-opened {
					margin-right: 0;
				}
			}
		}

		.main-menu-v2{
			.first-level-submenu {
				a {
					color: #dddcdd;
				}
			}
		}


		.header-card {
			position: absolute;
			top: 0;
			right: 32px;
			width: 261px;
			background: $accent-primary;
			z-index: 5;

			.card-text {
				padding: 37px 10px 12px;
				font-size: 13px;
				line-height: 17px;
				text-align: center;
				color: $white;

				p {
					margin: 0;
				}

				.site-page & {
					padding-left: 20px;
					text-align: left;
				}
			}

			.card-buttons {
				display: flex;
			}

			.card-btn {
				display: inline-block;
				flex: 1 1 50%;
				padding: 8px 12px;
				font-size: 15px;
				line-height: 20px;
				font-weight: 700;
				text-transform: uppercase;
				text-align: center;
				color: $body-text;
				background: $card-btn-bg;

				&:hover {
					text-decoration: none !important;
					background: $card-btn-hover-bg;
				}

				@include media('>=1200px') {
					&:nth-child(even) {
						margin-left: 1px;
					}
				}

				&.single-btn {
					position: relative;
					display: block;
					padding-left: 20px;
					padding-right: 45px;
					text-align: left;

					svg {
						position: absolute;
						display: block;
						top: 50%;
						right: 17px;
						width: 20px;
						height: 20px;
						margin-top: -10px;
					}
				}
			}

			@include media('<1200px') {
				width: 180px;

				.card-text {
					padding-top: 20px;
				}

				.card-buttons {
					display: block;

					.card-btn {
						display: block;
						margin-top: 1px;
					}
				}
			}

			@include media('<desktop') {
				width: 160px;
			}
			@include media('<tabport') {
				display: none;
			}
		}

		#menu-button {
			position: relative;
			flex: none;
			width: 32px;
			height: 32px;
			margin-left: 10px;

			.menu-button-icon,
			a {
				position: absolute;
				display: block;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: auto;
				height: auto;
			}

			.menu-button-icon {
				background: url("../img/icons/menu-icon.png") no-repeat center center;
				background-size: cover;
				z-index: 0;
			}

			a {
				opacity: 0;
				font-size: 0;
				line-height: 0;
				background: transparent;
				z-index: 1;
				cursor: pointer;
			}
		}
	}
}


ul.first-level-submenu {

}
ul.first-level-submenu{
	display: none;
	flex: 1 1 auto;
}
//
//
//$almost-black: #c6538c;
//$white: #fff;
//$dark: gray;
//
//ul.first-level-submenu {
//	position: absolute;
//	top: 100%;
//	left: -50%;
//	@include media("<=1365px") {
//		left: -170px;
//	}
//	padding: 0px 32px;
//	text-align: left;
//	background:$almost-black;
//	box-shadow: 0 2px 3px rgba(0,0,0,0.2);
//	z-index: 50;
//	max-height:0;
//	overflow:hidden;
//	transition:all 400ms ease;
//	display: flex;
//
//	@include media("<=wide-tab") {
//		display: none;
//		position: static;
//		width: 100%;
//		min-width:0;
//		height: auto;
//		top: auto;
//		left: auto;
//		padding: 0 0 15px 20px;
//		background-color: transparent;
//		box-shadow: none;
//		z-index: 1;
//	}
//
//	&>li {
//		padding: 32px 32px;
//		box-sizing: border-box;
//		border-bottom:0 none;
//		flex:1 1 300px;
//
//		a{
//			&:after{
//				display:none;
//			}
//			&.submenu-sibling{
//				//@include font('body', 17px, $white, 400,  $lh: 28px);
//			}
//			//@include font('body', 14px, map_get($headerFooter, colour), 400,  $lh: 1.55);
//			&:hover{
//				color:$white;
//			}
//		}
//		&>a{
//			&:hover:after{
//				display:none;
//			}
//		}
//
//		&>ul.second-level-submenu {
//			position: static;
//			margin: 16px 0 40px 0;
//
//			@include media("<=wide-tab") {
//				padding: 5px 0;
//				display: none;
//				min-width: 0;
//				height: auto;
//			}
//
//			&>li {
//				display: block;
//				position: relative;
//				padding: 0 0 0 30px;
//				border-bottom:0 none;
//
//				&::before,&::after {
//					position: absolute;
//					display: block;
//					top: 1em;
//					left: 1px;
//					content: "";
//					//background-color: map_get($headerFooter, colour);
//					z-index: 1;
//					height:10px;
//					width:10px;
//					transform: translateY(-50%) rotate(45deg);
//					transition:all 300ms ease;
//				}
//				&::after {
//					left: -1px;
//					background-color: $almost-black;
//					@include media("<=wide-tab") {
//						background-color: $dark;
//					}
//					z-index: 2;
//				}
//				&:hover:before{
//					left:8px;
//					background-color:$link-primary;
//				}
//				&:hover:after{
//					left:6px;
//				}
//
//				&>a {
//					display: block;
//					text-align: left;
//					padding:7px 0;
//					line-height: 22px;
//
//					@include media("<=wide-tab") {
//						position: relative;
//						padding: 6px 40px 6px 15px;
//						z-index: 5;
//					}
//					&:after{
//						height:1px;
//						top:1em;
//						left:-26px;
//						transform:translateY(2px);
//						width:0;
//						transition: width 300ms ease, opacity 0s;
//					}
//				}
//
//				&:hover > a{
//					//                    color:$link-primary;
//					&:after{
//						width:14px;
//						opacity: 1;
//						z-index:99999;
//					}
//					&:hover:after{
//						top:1em;
//						transform:translateY(2px);
//					}
//				}
//
//			}
//		}
//	}
//}
//
//ul.two-column-submenu {
//	//left: -230px;
//	width: 960px;
//
//	@include media("<=wide-tab") {
//		left: 0;
//		width: 100%;
//	}
//}
//
//ul.one-column-submenu {
//	width: 480px;
//	@include media("<=wide-tab") {
//		left: 0;
//		width: 100%;
//	}
//}
//
///* lockton recruitment specific */
//.site-locktonrecruitment {
//	ul.one-column-submenu {
//		width: auto;
//		white-space: nowrap;
//		ul.second-level-submenu {
//			margin: 16px 0;
//		}
//	}
//}
///* end lockton recruitment specific */
//
//.has-submenu {
//	a.submenu-sibling {
//		@include media("<=wide-tab") {
//			position: relative;
//			padding-right: 45px;
//
//			&::before {
//				display: block;
//				position: absolute;
//				right: 30px;
//				top: 50%;
//				content: "";
//				width: 10px;
//				height: 2px;
//				margin-top: -1px;
//				background: rgba(87, 103, 108, 0.9);
//				z-index: 1;
//			}
//
//			&::after {
//				display: block;
//				position: absolute;
//				right: 34px;
//				top: 50%;
//				content: "";
//				width: 2px;
//				height: 10px;
//				margin-top: -5px;
//				background: rgba(87, 103, 108, 0.9);
//				z-index: 1;
//			}
//		}
//		@include media("<=phoneland") {
//			&::before {
//				right: 20px;
//			}
//			&::after {
//				right: 24px;
//			}
//		}
//	}
//}
//ul.first-level-submenu.submenu-opened {
//	@include media("<=wide-tab") {
//		&::after {
//			display: none;
//		}
//	}
//	max-height:600px;
//}
