
.survey {
	margin: 17px 0 30px;

	@include media('<tabport') {
		margin-bottom: 0;
	}

	.survey-header {
		position: relative;
		display: flex;
		margin-bottom: 2px;
		padding: 8px;
		color: $survey-title-color;
		background: $survey-title-bg;
		box-sizing: border-box;

		.block-title,
		.block-content {
			font: 300 24px/34px $secondary-font;
    		letter-spacing: -0.5px;
			color: inherit;
			box-sizing: border-box;
		}

		.block-title {
			flex: 0 1 auto;
			min-width: 205px;
			margin: 0;
			padding: 17px 30px 18px 22px;
			border-right: 1px solid $white;
		}

		.block-content {
			display: flex;
			align-items: center;
			flex: 1 1 auto;
			padding: 17px 30px 18px;

			p {
				margin: 0;
			}
		}

		@include media('<tabport') {
			display: block;
			margin-bottom: 0;
			padding: 0;
			background: transparent;

			.block-title,
			.block-content {
				font-size: 20px;
				line-height: 26px;
			}

			.block-title {
				margin-bottom: 35px;
				padding: 17px 32px;
				background: $survey-title-bg;
			}

			.block-content {
				display: block;
				margin-bottom: 23px;
				padding: 0 32px;
				color: $body-text;
			}
		}

		@include media('<=phoneland') {
			.block-title,
			.block-content {
				padding-right: 16px;
				padding-left: 16px;
			}
		}
	}

	.survey-body {
		padding: 35px 15px 65px;
		border: 1px solid $alto;
		border-bottom-width: 7px;

		.inner-bounds {
			max-width: 855px;
			margin: 0 auto;
		}

		@include media('<tabport') {
			padding: 0 32px;
			border: none;

			.inner-bounds {
				&>p {
					font-size: 17px;
					line-height: 23px;
				}
			}
		}
		@include media('<=phoneland') {
			padding: 0 16px;
		}
	}

	// Styled checkboxes

	.survey-item {
		position: relative;
		margin-bottom: 2px;

		input[type="checkbox"] {
			position: absolute;
			width: 0;
			height: 0;
			opacity: 0;
			visibility: hidden;
			z-index: -1;
		}

		label {
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: transparent;
			z-index: 3;
			pointer-events: auto;
			cursor: pointer;
			box-sizing: border-box;
		}

		p {
			margin: 0;
		}

		.survey-item-content {
			position: relative;
			display: flex;
			align-items: flex-start;
			padding: 23px 32px 23px 20px;
			font: 300 18px/30px $primary-font;
			background-color: $survey-item-bg;
  			border: 1px solid $survey-item-border;
  			z-index: 1;
  			transition: all 0.3s ease;

  			.number {
  				flex: none;
  				min-width: 40px;
  				font-weight: 700;
  				color: $oxford-blue;
  				box-sizing: border-box;
  			}

  			.text {
  				flex: 1 1 auto;
  				padding: 0 15px;
  			}

  			.icon {
  				position: relative;
				flex: none;
				width: 30px;
				height: 30px;
				margin-left: 20px;
				box-sizing: border-box;

				svg {
					position: relative;
					display: block;
					width: 100%;
					height: 100%;
					opacity: 0;
					color: $oxford-blue;
					z-index: 1;
					transition: all 0.3s ease;
				}

				&::before {
					position: absolute;
					display: block;
					content: "";
					top: 4px;
					left: 4px;
					width: 22px;
					height: 22px;
					overflow: hidden;
					border: 2px solid $oxford-blue;
					border-radius: 50%;
					z-index: 0;
					transition: all 0.3s ease;
					box-sizing: border-box;
				}
  			}
		}

		input[type="checkbox"]:checked {
			& ~ .survey-item-content {
				background-color: $survey-item-checked-bg;
  				border: 1px solid $survey-item-checked-border;

  				.icon {
  					svg {
  						opacity: 1;
  					}

  					&::before {
  						transform: scale(0.1,0.1);
  					}
  				}
			}
		}

		label:hover {
			& ~ .survey-item-content {
				background-color: $survey-item-hover-bg;
				box-shadow: 0 0 7px 2px $survey-item-hover-border;
				border: 1px solid $survey-item-hover-border;
				transform: scale(1.015,1.015);
				z-index: 2;
			}
		}

		@include media('<tabport') {
			margin-bottom: 3px;

			.survey-item-content {
				font-size: 16px;
				line-height: 20px;

				.number {
	  				min-width: 30px;
	  			}

	  			.text {
	  				padding: 0 5px;
	  			}

	  			.icon {
					width: 25px;
					height: 25px;
					margin-top: -4px;
					margin-left: 15px;

					&::before {
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
	  			}
			}
		}

		@include media('<=phoneland') {
			.survey-item-content {
				min-height: 90px;
				padding: 14px 12px;
				box-sizing: border-box;
			}
		}
	}
}