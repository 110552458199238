
.site-page.trial {

	@include media('>=tabport') {
		.additional-section {
			.section-title {
				margin-bottom: 28px;
			}
			.section-text {
				margin-bottom: 48px;
			}
		}
	}

	@include media('>=desktop') {
		.content-section_v3 {
			.accordion-trigger {
				margin-top: 40px;
			}
			.accordion-content {
				margin-bottom: 30px;
			}
		}
	}
}