
.dashboard.dashboard-survey {
  @include media('<tabport') {
    .survey-section {
      .content-padding {
        padding: 0;
      }
    }
  }

  .top-section .section-header p.second-p{
    margin-top: 10px;
  }

}

