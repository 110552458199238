// style.scss

/*!
 *  Stunnplate style
 *  Version: 2.0
 */
/*
 *  Notes:
 *  Main SCSS file to include entire library followed by custom styles.
 * 
 */

@import 'variables';

/*-------------------------------------------------------*/
/*- HELPERS                                             -*/
/*-------------------------------------------------------*/
/*MG helpers have to go first! */

@import 'helpers/fonts';
@import 'helpers/mixins';
@import 'helpers/placeholders';
@import 'helpers/include-media';

/*-------------------------------------------------------*/
/*- BASE                                                -*/
/*-------------------------------------------------------*/

/* Normalise */
@import 'base/normalize';

/* Core */
@import 'base/default';

/*-------------------------------------------------------*/
/*- STUNNPLATE LIBRARY MODULES                          -*/
/*-------------------------------------------------------*/

/* Stunn Modules */
/*@import '../modules/simple-fixed-header/simple-fixed-header';*/
/*@!import '../modules/fixedHeader/fixedheader';*/
/*@!import '../modules/layout/layout';*/
@import '../modules/layout/flexlayout';
/*@!import '../modules/nav/nav';*/
@import '../modules/nav/flex-nav';
@import '../modules/accordion/accordion';
@import '../modules/tabbed/tabbed';
@import '../modules/modal/modal';
/*@import '../modules/carousel/carousel';*/
/*@!import '../modules/almostInView/almostInView';*/
/*@import '../modules/maps/maps';*/
/*@import '../modules/flexSlider/flexSlider';*/

/*-------------------------------------------------------*/
/*- COMMON ELEMENTS                                     -*/
/*-------------------------------------------------------*/

@import 'common';

/*-------------------------------------------------------*/
/*- COMPONENTS                                          -*/
/*-------------------------------------------------------*/

@import 'components/icons';
@import 'components/buttons';
@import 'components/forms';
@import 'components/social-icons';
@import 'components/banner';
@import 'components/accordions';
@import 'components/statistics';
@import 'components/tables';
@import 'components/survey';
@import 'components/lists';
@import 'components/text-tips';
@import 'components/pricing-block';
@import 'components/modal';

/*-------------------------------------------------------*/
/*- PAGE SPECIFIC / LAYOUTS								-*/
/*-------------------------------------------------------*/

//@import 'layouts/default';
@import 'layouts/header';
@import 'layouts/footer';

// DASHBOARD

@import 'layouts/dashboard';
@import 'layouts/dashboard-home';
@import 'layouts/login';
@import 'layouts/dashboard-programme';
@import 'layouts/dashboard-video-detail';
@import 'layouts/dashboard-survey';
@import 'layouts/dashboard-profile';
@import 'layouts/contact-page';
@import 'layouts/dashboard-survey-results';

// PROMO SITE

@import 'layouts/promo-site';
@import 'layouts/site-programme';
@import 'layouts/site-how-it-works';
@import 'layouts/site-trial';
@import 'layouts/site-pricing';
@import 'layouts/site-home';
@import 'layouts/site-resources';

/*-------------------------------------------------------*/
