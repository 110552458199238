
.social-icons {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	&.block {
		display: flex;
	}

	.icons-wrap {
		display: flex;
		justify-content: flex-end;

		.icon {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 65px;
			height: 65px;
			text-align: center;
			border-bottom: 1px solid $cararra;

			svg {
				flex: none;
			}

			&:hover {
				background: $s_link-hover-bg;
				border-bottom-color: $s_link-hover-bg;
			}

			&.facebook {
				&:hover {
					background: $s_facebook-hover-bg;
					border-bottom-color: $s_facebook-hover-bg;
				}
			}

			&.twitter {
				&:hover {
					background: $s_twitter-hover-bg;
					border-bottom-color: $s_twitter-hover-bg;
				}
			}

			&.linkedin {
				&:hover {
					background: $s_linkedin-hover-bg;
					border-bottom-color: $s_linkedin-hover-bg;
				}
			}

			&.vimeo {
				&:hover {
					background: $s_vimeo-hover-bg;
					border-bottom-color: $s_vimeo-hover-bg;
				}
			}
		}
	}

	&.social-icons_v2 {
		display: block;

		.icons-wrap {
			.icon {
				border-top: 1px solid $cararra;
				border-bottom: none;

				&:hover {
					border-top-color: $s_link-hover-bg;
					border-bottom: none;
				}

				&.facebook {
					&:hover {
						border-top-color: $s_facebook-hover-bg;
					}
				}

				&.twitter {
					&:hover {
						border-top-color: $s_twitter-hover-bg;
					}
				}

				&.linkedin {
					&:hover {
						border-top-color: $s_linkedin-hover-bg;
					}
				}

				&.vimeo {
					&:hover {
						border-top-color: $s_vimeo-hover-bg;
					}
				}
			}
		}
	}
}