
.dashboard.dashboard-programme {

  .accordion-section {
    padding-bottom: 85px;
  }

  @include media('>=tabport') {
    .top-section {
      .section-header {
        p {
          display: none;
        }
      }
    }
  }

  @include media('<wide-tab') {
    .accordion-section {
      padding-bottom: 30px;
    }
  }

  @include media('<tabport') {
    .main {
      padding-bottom: 0;
    }

    .accordion-section {
      padding-bottom: 0;

      .bounds {
        padding: 0;
      }
    }
  }
}