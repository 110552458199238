
.page-footer {
	padding: 30px 0 34px;
	background: $footer-bg;
	border-top: 6px solid $footer-border;

	p {
		margin-bottom: 0;
	}

	.content-container {
		flex-wrap: nowrap;
		max-width: 1440px;
		margin: auto;

		.block {
			min-width: 150px;
		}

		.logo {
			flex: none;
			margin-right: 28px;
			opacity: 0.86;

			a {
				display: block;
			}
		}

		.copyright {
			display: flex;
			flex-direction: column;
			justify-content: center;
			flex: 1 1 auto;
			font-size: 14px;
			line-height: 1.14;
			font-weight: 300;
			letter-spacing: -0.2px;
			//color: $white;
            color: #cecbcb;

			p {
				margin: 0;
			}

			a {
				display: inline-block;
			}
		}

		.social-icons {
			flex: none;
		}
	}


	.footer-menu{

		justify-content: space-between;

		ul, li {
			color:white;
			list-style-type: none;
			font-family: $primary-font;
			font-size: 13px;
			font-weight: 300;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.77;
			letter-spacing: normal;
			color: #cecbcb;
		}
		.submenu-title {
			font-weight: 500;
			color: #ffffff;
		}

		.submenu-wrap{
			//margin-left: 15px;
		}

		ul.first-level-submenu{
			display: block;
			flex: 1 1 auto;
		}
	}

	.flex-ul {
		flex-direction: row;
		flex-wrap: wrap;

		li{
			margin-bottom: 5px;
		}
	}

	.social-icons.block {
		display: block;
	}

	.block-subscribe{
		display: flex;
		flex: 1 1 auto;
		max-width: 150px;
		p{
			font-family: $primary-font;
			font-size: 16px;
			font-weight: 300;
			color: $white;
			span{
				font-size: 36px;
				font-weight: 300;
				color: $white-release2;
				display: block;

			}

		}
	}



	.block-submit{
		flex: 1 1 auto;
		margin: 0 40px;
		background-color: #525252;
		padding: 1%;

		//box-sizing:  border-box;
		.form-wrap{
			width: 100%;
			height: 100%;
			background-color: #403f3f;;

          form{
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 100%;
            .input-email{
              height: 100%;
              width: 100%;
              border: none;
              font-size: 16px;
              font-weight: 300;
              line-height: 1.38;
              letter-spacing: 0.43px;
              color: #cecbcb;
            }

            .input-submit{
              height: 100%;
              width: 110px;
              background-color: $grayish-yellow;

              font-family: Roboto;
              font-size: 11px;
              letter-spacing: 4px;
              text-align: center;
              color: $white;
            }

            .envelop-icon{
              padding: 0 20px;
              svg{
                fill: white;
              }
            }
          }


		}
	 }

	.copyright{
		flex: 1 1 auto;
		max-width: 200px;

	}

	@include media('<tabport') {
		padding: 30px 0 0;

		.content-container {
			display: block;
			padding-top: 0;
			padding-bottom: 0;

			.social-icons {
				padding-bottom: 0;

				.icons-wrap {
					justify-content: space-between;

					.icon {
						flex: 1 1 25%;
						width: auto;
						height: 69px;
						border-top: 1px solid $white;
						border-bottom: none;
					}
				}
			}



			.block-subscribe{
				max-width: 100%;
				margin: 15px 0 15px 0;
				span{
					display: inline;
				}
			}

			.block-submit {
				margin: 15px 0 15px 0;
				padding: 1%;
			}
			.copyright{
				margin: 0 0 35px;
			}
			.block-submit .form-wrap form  {
				height: 40px;
			}

			.flex-ul {
				flex-direction: row;
				flex-wrap: wrap;

				li{
					margin-right: 5px;
				}
			}

		}


	}

	@include media('<phoneland') {
		.content-container {
			.logo {
				margin: 0 0 44px;

				img {
					max-width: 147px;
					width: 100%;
				}
			}

			.copyright {
				margin-bottom: 85px;
			}
		}
	}


}