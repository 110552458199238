
.list {
	margin: 35px 0 20px;
	padding: 0 0 0 37px;
	list-style: none;

	li {
		position: relative;

		&::before {
			position: absolute;
			display: block;
			content: "";
			top: 0;
			left: -37px;
			width: 18px;
			height: 18px;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: contain;
		}
	}

	// LIST - V1

	&.list_v1 {

		li {
			font-size: 17px;

			& + li {
				margin-top: 27px;
			}

			&::before {
				background-image: url("../img/icons/check.svg");
			}

			.number {
				font-weight: 700;
				color: $oxford-blue;
			}
		}

		@include media('<tabport') {
			margin-top: 27px;

			li {
				font-size: 16px;
				line-height: 22px;
				
				& + li {
					margin-top: 20px;
				}
			}
		}
	}

	// LIST - V2

	&.list_v2 {
		margin: 15px 0 25px;

		li {
			font: inherit;

			& + li {
				margin-top: 12px;
			}

			&::before {
				top: 2px;
				background-image: url("../img/icons/check-symbol.svg");
			}
		}

		@include media('<tabport') {
			margin: 20px 0;
		}
	}

	// LIST - V3

	&.list_v3 {
		margin: 15px 0 42px;
		padding-left: 27px;
		font-size: 17px;
		text-align: left;

		li {
			font: inherit;

			& + li {
				margin-top: 10px;
			}

			&::before {
				top: 2px;
				left: -27px;
				background-image: url("../img/icons/checkmark.svg");
			}
		}

		@include media('<tabport') {
			margin: 20px 0 25px;
		}
	}
}
