
.login-page {
	.page-header {
		.site-nav {
			padding-right: 32px;

			.main-menu-wrap {
				.logout-btn {
					display: none;
				}
				@include media('>=tabport') {
					display: none;
				}	
			}
			.header-card {
				display: none;
			}
		}
	}

	.form-container {
		.text-block {
			text-align: center;
			opacity: 0.96;

			p {
				margin-bottom: 4px;
				font-size: 17px;
				font-weight: 700;
				line-height: 22px;
				color: $white;

				a {
					color: $accent-secondary;
				}
			}
		}
	}

	@include media('>=tabport') {
		.form-container {
			.text-block {
				display: none;
			}
		}
	}

	@include media('<tabport') {
		.page-header {
			position: absolute;
			background: transparent;

			.site-nav {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-end;

				.logo {
					align-self: center;
					margin: 35px auto;
					order: 1;

					img {
						max-width: none;
						width: 147px;
						height: auto;
					}
				}

				.main-menu-wrap {
					align-self: flex-end;
					order: 0;
				}
			}
		}
	}

	@include media('<=phoneland') {
		.page-header {
			.site-nav {
				padding-right: 16px;
			}
		}
	}
}


// IE 11 Fixes

.ie10, .ie11 {
	.login-page {
		.page-container {
			height: 100vh;
			overflow-y: auto;
		}
	}
}