
.site-page.homepage {

	.banner {
		margin: 0;
		color: $white;
		background: none;

		.bg-image {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: #32444F no-repeat center bottom;
			background-size: cover;
			z-index: -1;
		}

		.main-block {
			
			.block-content {
				max-width: 330px;
			}

			.link-wrap {
				margin-bottom: 40px;
				padding-top: 8px;
			}
		}
	}

	.accordion-section {
		.content-section {
			.content-wrap {
				.block {
					&:first-child,
					&:nth-child(2) {
						padding-top: 0;
					}				
				}

				.single-col {
					padding: 0 16px;
					box-sizing: border-box;

					.btn_a {
						background: transparent;

						&:hover {
							background: $a_btn-hover-background;
						}
					}
				}
				.single-col-no-padding {
					padding: 0;
				}
			}
		}
	}


	@include media('>desktop') {
		.banner {
			padding: 295px 0 120px;

			.page-title {
				//font-size: 80px;
				opacity: 0.93;
			}
		}
	}

	@include media('>=tabport') {
		.accordion-section {
			.content-section {
				padding: 60px 0;

				.ai-title {
					i {
						font-style: normal;
					}
				}

				.video-wrap, .image-wrap {
					margin: 10px 0;
				}

				.content-wrap {
					.block {
						flex: 1 1 50%;
						max-width: 50%;
					}

					.single-col {
						flex: 1 1 100%;
						max-width: 100%;
					}
				}
			}
		}


		.landing-page {
			.accordion-section .content-section {
				padding: 0;
				.accordion-content{
					padding: 102px 0;
				}
			}
		}

		.additional-section {
			//padding: 100px 0 92px;
			padding: 150px 0 142px;
			background: none;
			.section-title {
				margin-bottom: 47px;
			}
		}
	}


	@include media('<tabport') {
		.banner {
			.main-block {
				.link-wrap {
					margin-bottom: 20px;
				}
			}
		}

		.accordion-section {
			.content-section {
				background: $cararra-2;

				.ai-title {
					i {
						font-weight: 300;
					}
				}

				.content-wrap {
					.single-col {
						padding: 0 16px 10px;
					}
				}
			}
		}
	}


	@include media('<=phoneland') {
		.page-header {
			.site-nav {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-end;

				.logo {
					align-self: center;
					margin: 35px auto 42px;
					order: 2;

					img {
						max-width: none;
						width: 147px;
						height: auto;
					}
				}

				.main-menu-wrap {
					align-self: flex-end;
					order: 1;
				}
			}
		}

		.banner {
			padding: 0 0 15px;
			background: $accent-primary;

			.bg-image {
				position: relative;
				height: 228px;
				margin: 0 -16px;
				z-index: 1;
			}

			.page-title,
			.link-wrap {
				display: none;
			}

			.main-block {
				.block-content {
					max-width: none;
					margin: 30px 0;
				}
			}
		}

		.accordion-section {
			.content-section {
				.content-wrap {
					.single-col {
						padding-left: 8px;
						padding-right: 8px;
					}
				}
			}
		}

		.additional-section {
			padding-top: 42px;

			.section-title + .section-btn {
				margin-top: 140px;
			}
		}
	}


	.content-and-discount {
		padding: 0;

		.block-content.block {
			padding: 0 8px;
		}
	}

	.discount {
		transform: rotate(4deg);
		text-align: -webkit-right;
		min-width: 350px;
		display: none;

		&.has-discount{
			display: inline-block;
		}

		@include media("<646px") {
			text-align: -webkit-center;
		}

		@include media("<411px") {
			min-width: 300px;
		}

		@include media("<346px"){
			min-width: 240px;
		}

		.discount-up {
			height: 175px;
			width: 350px;
			border-top-left-radius: 400px;
			border-top-right-radius: 400px;
			background-color: #cdcea4;
			text-align: center;
			padding-top: 100px;

			@include media("<411px") {
				height: 150px;
				width: 300px;
				padding-top: 78px;
			}

			@include media("<346px"){
				height: 120px;
				width: 240px;
				padding-top: 65px;
			}

			.discount-amount {
				.discount-value {
					font-family: $alternative-font;
					font-size: 62.7px;
					font-weight: bold;
					line-height: 1.33;
					color: #31404c;
					margin-bottom: 9px;

					@include media("<346px") {
						font-size: 47.7px;
					}
				}
			}
		}

		.discount-down {
			height: 175px;
			width: 350px;
			border-bottom-left-radius: 400px;
			border-bottom-right-radius: 400px;
			background-color: #31404c;
			text-align: center;

			@include media("<411px") {
				height: 150px;
				width: 300px;
			}

			@include media("<346px"){
				height: 120px;
				width: 240px;
			}

			.discount-expire-date {
				font-family: $primary-font;
				font-size: 30px;
				font-weight: normal;
				line-height: 1.33;
				color: #cdcea4;
				margin-bottom: 9px;
				padding-top: 4%;

				@include media("<346px") {
					font-size: 22px;
				}
			}

			.discount-link {
				border: 1px solid #b8bdbc;
				max-width: 170px;
				width: 100%;
				height: 40px;
				box-sizing: border-box;
				padding: 8px;
				margin: auto;

				font-family: $primary-font;
				font-size: 18px;
				letter-spacing: 5.23px;
				color: #b8bdbc;

				@include media("<346px") {
					max-width: 130px;
					padding: 10px;
					font-size: 15px;
				}
			}
		}
	}
}