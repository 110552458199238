
.dashboard.dashboard-survey-results {

  .content-wrap {
    p {
      strong {
        font-weight: 500;
      }
    }
  }

  // TOP SECTION

  .top-section {
    .section-header {
      position: relative;

      .section-title,
      p {
        padding-right: 330px;
      }

      .statistics-total {
        position: absolute;
        top: -2px;
        right: -2px;
      }
    }
  }

  // MOTIVATION INDEX SECTION

  .motivation-index-section {

    h3 {
      margin: 15px 0 40px;
    }

    .line {
      margin-top: 40px;
    }
  }

  // RESPONSES SECTION

  .responses-section {
    padding: 40px 0 60px;

    .title-wrap {
      h3 {
        margin-bottom: 10px;
      }
    }

    .accordion-wrap {
      margin-top: 8px;
    }
  }

  // PERFORMANCE GRADE SECTION

  .performance-grade-section {
    .content-wrap {
      padding-top: 45px;
      padding-bottom: 48px;

      .left-block {
        display: flex;
        flex-direction: column;
        border-right: 1px solid $default-border;

        h3 {
          flex: none;
          margin: 7px 0 20px;
        }

        .score-block {
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex: 1 1 auto;
          padding: 10px 0 20px;

          .range-input-wrap {
            margin-bottom: 20px;
          }

          p {
            text-align: center;
          }
        }
      }
    }
  }

  // DEVELOPMENT PLAN SECTION

  .development-plan-section {
    padding-top: 50px;

    .text-wrap {
      max-width: 890px;
      margin: 45px auto 35px;

      .main-text {
        margin-bottom: 30px;
        font-size: 20px;
        line-height: 25px;
      }
    }

    .content-wrap {
      .form-block {
        display: flex;
        flex-direction: column;
      }
    }
  }

  // DIFFERENCES

  @include media('>phoneland') {
    .responses-section {
      .accordion {
        .accordion-item {
          .accordion-content {
            .content-wrap {
              padding-bottom: 35px;
            }
          }
        }
      }
    }
  }

  @include media('>=tabport') {
    .responses-section {
      .accordion {
        .accordion-item {

          .accordion-trigger {
            .title-tert {
              width: 250px;
            }
          }

          .accordion-content {
            .content-wrap {
              max-width: 985px;
            }
          }
        }
      }
    }
  }

  @include media('>desktop') {
    .motivation-index-section {
      .content-wrap {
        .left-block {
          padding-right: 21px;
        }
        .right-block {
          padding-left: 21px;
        }
      }
    }
    .development-plan-section {
      .content-wrap {
        .text-tips {
          padding-right: 44px;
        }
        .form-block {
          padding-left: 44px;
        }
      }
    }
  }

  // MOBILE VERSIONS

  @include media('<wide-tab') {
    .motivation-index-section {
      .content-wrap {
        &, .block {
          display: block;
        }

        .line {
          margin-top: 50px;
        }
      }

      .content-padding {
        .line {
          margin: 0;
        }
      }
    }

    .responses-section {
      .title-wrap {
        h3 {
          margin-top: 10px;
        }
      }
      .content-wrap {
        &, .block {
          display: block;
        }
        .left-block {
          padding-bottom: 0;

          p:last-child {
            margin-bottom: 0;
          }
        }
        .right-block {
          padding-top: 0;
        }
      }
    }

    .performance-grade-section {
      .content-wrap {
        padding-top: 25px;

        &, .block {
          display: block;
          border: none;
        }
        .table_v2-wrap,
        .score-block p {
          margin: 0;
        }
        .left-block .score-block {
          padding-top: 25px;
        }
        .table_v2 tr td.icon {
          text-align: left;
        }
      }
    }

    .development-plan-section {
      .title-wrap {
        h3 {
          margin-top: 0;
        }
      }
      .text-wrap {
        margin: 40px auto 20px;
      }
      .content-wrap {
        &, .block, .notes-form, .flex-col {
          display: block;
          flex: none;
        }
        .text-tips {
          margin-bottom: 30px;
        }
        .notes-form {
          .form-textarea {
            flex: none;
            min-height: 196px;
            margin-bottom: 38px;
          }
        }
      }
    }
  }

  @include media('<tabport') {
    .top-section {
      .section-header {
        .section-title,
        p {
          padding-right: 0;
        }

        .statistics-total {
          position: static;
          top: auto;
          right: auto;
          margin-top: 35px;
          text-align: center;
        }
      }
    }

    .motivation-index-section {
      h3 {
        margin: 0 0 25px;
      }
      .content-wrap {
        padding-top: 0;

        .left-block {
          padding-top: 12px;
          padding-bottom: 20px;
        }
        .right-block {
          padding-top: 20px;
        }
        .line {
          margin-top: 40px;
        }
        .statistics-block {
          margin-top: 30px;
        }
      }
    }

    .responses-section {
      .title-wrap {
        h3 {
          margin: 0 0 25px;
        }
      }
      .content-wrap {
        padding-top: 0;
        padding-bottom: 15px;

        .left-block {
          padding-top: 0;
        }
        .right-block {
          padding-bottom: 0;
        }
      }

      .accordion {
        .accordion-item {
          .accordion-trigger {
            .title-tert {
              &::after {
                display: none;
              }
            }
            .content-tert {
              font-size: 17px;
              text-align: left;
              color: $mine-shaft-2;
              background: $white;
              border-left: 1px solid $alto;
              border-right: 1px solid $alto;

              &::before,
              &::after {
                display: none;
              }
            }
          }
          .accordion-content {
            .content-wrap {
              padding-top: 0;

              .group {
                .group-title {
                  margin-bottom: 0;
                }
              }

              .ticked-items {
                .group-title {
                  margin-top: 0;
                  padding-top: 0;
                }
              }

              .content-block {
                .content-block-title {
                  padding-top: 3px;
                  font-size: 16px;
                  line-height: 20px;
                }
              }
            }
          }
        }
      }
    }

    .performance-grade-section {
      .content-wrap {
        padding-top: 32px;

        .left-block {
          padding-top: 0;
        }
        .right-block {
          padding-bottom: 10px;
        }
      }
    }

    .development-plan-section {
      padding-top: 40px;

      .text-wrap {
        margin-top: 35px;

        .main-text {
          margin-bottom: 25px;
          font-size: 17px;
          line-height: 23px;
        }
      }
    }
  }

  @include media('<=phoneland') {
    .main {
      padding-bottom: 40px;
    }

    .responses-section {
      .accordion {
        .accordion-item {
          .accordion-content {
            padding-left: 16px;
            padding-right: 16px;

            .content-wrap {
              padding-bottom: 20px;

              .content-block {
                padding: 25px 0 20px;
                background: transparent !important;

                &:not(:last-child) {
                  border-bottom: 1px solid $alto;
                }

                .content-block-image {
                  margin-bottom: 24px;
                  padding-top: 0;
                }

                .content-block-title {
                  padding-top: 0;
                }
              }
            }
          }
        }
      }
    }

    .performance-grade-section {
      .content-wrap {
        .score-block {
          p {
            display: none;
          }
        }
        .table_v2 tr td.icon {
          text-align: center;
        }
      }
    }
  }
}