
.contact-page {

	&.site-page {
		.contact-section {
			padding: 15px 0 45px;
		}
	}
	// font: font-style font-variant font-weight font-size/line-height font-family;

	.contact-section {
		h3, .block-title {
			margin: 0 0 30px;
		    font: 300 36px $secondary-font;
		    letter-spacing: normal;
			color: #3c3c3b;
		}

		h5, .subtitle {
			margin: 0 0 12px;
			text-transform: uppercase;
		}

		.block-subtitle {
			font-size: 17px;
			font-weight: bold;
			line-height: 1.18;
		}

		.form-wrap{
			margin-top: 61px;
		}
		.content-wrap {


			.left-block {
				flex: 1 1 65%;
				max-width: 65%;
			}

			.right-block {
				flex: 1 1 35%;
				max-width: 35%;
			}

			//.block {
			//	flex: 1 1 0%;
			//	max-width: 50%;
			//}

			.left-block {
				&>p {
					font-size: 17px;
				}
			}
		}

		.contact-info {
			margin: 55px 0 35px;

			p {
				font-size: 18px;
				line-height: 23px;
				margin-bottom: 5px;
			}

			p .a-link {
				font-weight: 400;
				color: $astral;

				&:hover {
					color: $hover-primary;
				}
			}

			address {
				font-size: 18px;
				line-height: 26px;
				font-style: normal;
			}
		}	
	}





	    .aside-block{


	        .aside-author-wrap{
	            width: 100%;
	            background-color: $yvory;
	            padding: 27px;

				img{
					width: 100%;
				}
	            p {
	                font-family: $primary-font;
	            }
	            .author-text{

	                font-size: 17px;
	                font-weight: bold;
	                line-height: 1.18;
	                color: #20262a;
	                margin-top: 25px;
	                margin-bottom: 7px;
	            }
	            hr{
	                margin-top: 0px;
	            }
	            .author-name{
	                font-size: 32px;
	                font-weight: 300;
	                line-height: 1.25;
	                color: #3c3c3b;
	                margin-bottom: 30px;
	            }


	        }
	}




	@include media('>=desktop') {
		&:not(.site-page) {
			.main {
				padding: 52px 0 125px;
			}
		}

		&.site-page {
			.contact-section {
				padding: 30px 0 100px;
			}
		}

		.contact-section {
			.content-wrap {
				.left-block {
					padding-right: 40px;
				}
			}
		}
	}

	@include media('>=tabport') {
		.top-section {
			display: none;
		}

		.contact-section {
			.contact-form {
				position: relative;
				margin-top: -7px;
			}
		}
	}

	@include media('<tabport') {
		.top-section {
			padding-bottom: 12px;
		}

		.contact-section {
			.content-wrap {
				&, .block {
					display: block;
					max-width: none;
				}
			}

			h3, .block-title {
				margin-bottom: 20px;
				font-size: 22px;
			}

			h5, .subtitle {
				display: none;
			}

			.contact-info {
				margin-top: 40px;
			}
		}
	}
}