
.site-page.how-it-works {

	@include media('>=tabport') {
		.additional-section {
			.section-title {
				margin-bottom: 80px;
			}
		}

		.page-container {

			.ie10 &,
			.ie11 & {
				display: block;
			}
		}
	}
}