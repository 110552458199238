
.dashboard.dashboard-profile {

  .upgrade-title {
    display: flex;
    justify-content: center;
    color: #1CA54F;
  }
  .accordion-section {
    .accordion {

      .accordion-item {
        &.video-section {
          .accordion-content {
            .video-block {

              .video {
                position: relative;
                height: 300px;
                margin-bottom: 20px;
                background: #434749;
                border: 6px solid #434749;
                box-sizing: border-box;

                iframe {
                  width: 100%;
                  height: 100%;
                }

                .rating {
                  position: absolute;
                  top: 8px;
                  right: 60px;
                  font-size: 16px;
                  text-align: right;
                  color: $white;
                  z-index: 5;

                  .rating-value {
                    font-weight: 700;
                  }
                }
              }
            }
          }
        }
      }

      @include media('>=tabport') {

        & > .accordion-item:first-child {
          padding-top: 0;
          border-top: none;
        }

        .accordion-item {
          padding-top: 50px;
          padding-bottom: 70px;
          border-top: 1px solid $default-border;

          .accordion-content {
            .content-wrap {
              .video-block {
                flex: 1 1 50%;
                max-width: 50%;
                min-width: 0;
              }
            }
          }

          &.video-section {
            .accordion-content {
              padding-top: 25px;

              .content-wrap {
                .video-block {
                  padding: 0 16px;

                  .video {
                    height: 210px;
                  }
                }
              }
            }
          }

          &.personal-motivation {
            padding-top: 55px;
            padding-bottom: 30px;

            .accordion-trigger {
              position: relative;
              max-width: 50%;
            }

            .accordion-content {
              .content-wrap_top {
                position: relative;
                margin-top: -16px;

                .statistics-total {
                  position: relative;
                  margin-top: -90px;
                  text-align: right;

                  .result-block {
                    .text {
                      line-height: 15px;
                    }
                  }
                }
              }
            }
          }

          &.table-section {
            .accordion-content {
              .table_v1 {
                td, th {
                  width: auto;
                }
              }
            }
          }
        }

        .ai-container {
          display: flex;
          justify-content: space-between;

          .accordion-item {
            flex: 1 1 50%;
            max-width: 50%;

            &:nth-child(odd) {
              padding-right: 23px;
            }

            &:nth-child(even) {
              padding-left: 23px;
            }
          }
        }
      }

      @include media('>=wide-tab') {
        .accordion-item {
          .accordion-content {
            .content-wrap {
              .video-block {
                flex: 1 1 33.33%;
                max-width: 33.33%;
              }
            }
          }

          &.table-section {
            .accordion-content {
              padding: 30px 0 10px;
            }
          }
        }
      }

      @include media('>=1200px') {
        .accordion-item {
          &.video-section {
            .accordion-content {
              .content-wrap {
                margin: 0 -32px;

                .video-block {
                  padding: 0 32px;
                }
              }
            }
          }
        }
      }
    }
  }

  @include media('<wide-tab') {
    .main {
      padding-bottom: 0;
    }
  }

  @include media('<tabport') {
    .accordion-section {
      margin-top: 20px;

      .bounds {
        padding: 0;
      }

      .accordion {
        .accordion-item {

          .accordion-content {
            .content-wrap {
              display: block;

              &:first-child {
                padding-top: 32px;
              }

              &:last-child {
                padding-bottom: 26px;
              }

              .block {
                display: block;
                flex: none;
                max-width: none;
              }
            }

            .single-col {
              padding-top: 32px;
              padding-bottom: 26px;
            }

            .statistics-total {
              text-align: center;
            }
          }

          &.table-section {
            background: $wild-sand;

            .table_v1-container {
              padding-top: 16px;
            }
          }
        }
      }
    }
  }

  @include media('<=phoneland') {
    .accordion-section {
      .accordion {
        .accordion-item {
          &.video-section {
            .accordion-content {
              .video-block {
                .video {
                  height: 196px;
                }
              }
            }
          }
        }
      }
    }
  }
}