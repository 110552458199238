
.site-page {

	// CONTENT SECTIONS

	.content-section {
		position: relative;
		padding: 50px 0 40px;



		&.content-section_v2 {
			background: $cararra-3;
		}

		&.content-section_v3 {
			//background: $cararra-3;
			background: $mine-shaft-2;

		}

		&.content-section_v4 {
			.accordion-content {
				h2 {
					b, strong {
						font-weight: 500;
					}
				}
			}
		}


		.image-wrap {
			margin-bottom: 16px;
			//padding: 10px;
			//background: $oslo-grey;

			img {
				display: block;
				width: 100%;
				height: auto;
			}

			@include media('<tabport') {
				text-align: center;
			}
		}

		.bg-image {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: #33302C no-repeat center center;
			background-size: cover;
			z-index: -1;
		}

	}




	.landing-page{

		.content-section_v2{
			.card-container{
				max-width: 483px;
				color: #ffffff;

				h1 { color: #f2f2ec; }
				h2 { color: $cararra-3; }
				p { color: #c5c5c5; }
				.btn.btn_a {
					color:$white;
					border-color: $white;
				}
			}
			.card-container-bg-white{
				background-color: white;
				h1 { color: $mine-shaft-2;}
				h2 ,p{  color: #1a1a1a; }

				padding:1px 30px 30px 30px;
				max-width: 100%;
				opacity: 0.94;
				box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.36);

			}


			.aside-list{
				max-width: 370px;
				background-color: #262525;
			}

			.aside-list-bg-transparent{
				max-width: 400px;
				background-color: transparent;
				.aside-list-item {

					//padding-left: 0;
					padding-right: 0;
				}

				.icon-wrap{
					margin-left: 0;
				}

			}

		}


		.landing-page {
			.accordion-section .content-section {
				padding: 0;
			}
		}

		//.bg-white{
		//	color: #1a1a1a;
		//	h1 {  color: #363636; }
		//	p ,h2 { color: #1a1a1a; }
		//
		//}

		#slideMenu5{
			.accordion-content{
				padding: 102px 0 50px 0;
				.card-container{
					max-width: 543px;
					.img-the-power-of-mojo{
						img{
							max-width: 347px;
					}

					}
					.big-h1{
						font-size: 80px;
					}

					p{
						font-size: 26px;
						color: $white;
					}


					//height: 245px;
				}

				@include media('<tabport') {
					padding: 0;
				}
			}
		}

		.accordion-content{
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		}

	}

	.landing-page-employee{

		.slideMenu1{
			.subtitle{
				font-family: Roboto;
				font-size: 26px;
				font-weight: 500;
				line-height: 1.31;
				text-align: center;
				color: $god-grey;
			}
			.warp-text{
				display: flex;
				flex-direction: row;
				.block1{
					font-size: 26px;
					font-weight: 500;
					line-height: 1.31;
					color: $god-grey;
					min-width: 32px;
					margin-bottom: 29px;

				}
				.block2{

				}
			}
		}
		.section-be-the-ceo{
			//padding: 150px 0;
			display: flex;
			min-height:  768px;
			.content-padding{
				margin-top: auto;
				margin-bottom: auto;
			}
		}
	}



	.mojo-for-leaders{

		.section-list-text{
			.subtitle{
				font-family: Roboto;
				font-size: 26px;
				font-weight: 500;
				line-height: 1.31;
				text-align: center;
				color: $god-grey;
			}
			.warp-text{
				display: flex;
				flex-direction: row;
				.block1{
					font-size: 26px;
					font-weight: 500;
					line-height: 1.31;
					color: $god-grey;
					min-width: 32px;
					margin-bottom: 29px;

				}
				.block2{

				}
			}
		}
		.section-be-the-ceo{
			//padding: 150px 0;
			display: flex;
			min-height:  768px;
			.content-padding{
				margin-top: auto;
				margin-bottom: auto;
			}
		}
	}


	.landing-page-independent-coach{
		#slideMenu4 .accordion-content{
			//padding: 137px 0;
			@include media('<tabport') {
				.content-padding{padding:50px 0;}
			}
		}

		.attend-a-train{
			padding: 136px 0;
			.content-wrap{
			//	background-color: $white;
				 box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.36);
				  background-color: $cararra-3;
				.card-container-Workshop{
					//.card-container-header{
						.header-wrap{
							min-height: 156px;
						}

						h1{
							//font-family: Sucrose-Two;
							font-size: 46px;
							font-weight: bold;
							line-height: 1;
							letter-spacing: -1.04px;
							color: $mine-shaft-2;

							margin: 20px 0 25px;
							padding: 0 0 18px;
							//min-height: 114px;

							@include media('<=desktop') {
									font-size: 35px;
							}

							@include media('<=tabport') {
								font-size: 30px;
							}
						}





						h2{
							font-family: Roboto;
							font-size: 26px;
							font-weight: 300;
							line-height: 1.31;
							color: $god-grey;
							//min-height: 114px;
						}

						h3{
							font-size: 20px;
							line-height: 1.15;
							color: $god-grey;
						}
						hr{
							background-color: $god-grey;;
						}

					//}

					.container-watch-download{

						max-width:  334px;

					}

						.price-wrap{
							vertical-align: text-top;
							display: flex;
							flex-direction: row;
							//justify-content: flex-start;
							align-items: flex-start;
							//flex-wrap: wrap;
							min-height: 66px;

							.price-symbol{
								//font-family: Yantramanav;
								font-size: 20px;
								font-weight: 500;
								letter-spacing: 2.5px;
								color: $mine-shaft-3;
								padding-right: 7px;
							}
							.price{
							 	font-family: Roboto;
							   font-size: 50px;
							   font-weight: 300;
							   text-align: center;
							   color: $mine-shaft-3;
								line-height: 0.8;
								padding-right: 7px;

							}
							.text-discount, .text-per-seat{
								//font-family: Yantramanav;
								font-size: 14px;
								line-height: 1.14;
								color: $limed-spruce-2;
								padding-right: 6px;
							}
							.text-discount{
								width: 254px;
								//display: flex;
								//flex-wrap: wrap;
								margin-left: auto;
							}

						}



				}
			}
		}



		@include media('<=phoneland') {

			.right-block-innovation {
				margin-top: 50px;
			}
		}
	}

	.how-to-set-your-team{
		min-height: 912px;
		padding-top: 171px;
		background-size: cover;
	}

	@include media('>desktop') {
		.content-section {
			.content-wrap {
				.left-block {
					padding-right: 25px;
				}
				.right-block {
					padding-left: 25px;
				}
			}
		}
	}

	@include media('>=tabport') {
		.content-section {
			.content-wrap {
				h3 {
					margin: 10px 0 25px;
					line-height: 30px;
				}
				.video-wrap, .image-wrap {
					margin: 15px 0 25px;
				}
			}

			&.content-section_v3 {
				.title-tert {
					font: normal normal 46px/1 $alternative-font;
					text-transform: uppercase;
					letter-spacing: -0.5px;
					color: $cararra;
					border-color: $white;
				}
				.accordion-content {
					color: $white;
				}
			}

			&.content-section_v4 {
				padding-bottom: 25px;

				.accordion-content {
					color: $white;

					* {
						color: inherit;
					}

					h2 {
						margin: 0 0 30px;
						padding-bottom: 30px;
						font-size: 30px;
						border-color: $white;
					}
				}

				.content-wrap {
					min-height: 405px;
					align-items: center;
					align-content: center;
				}
			}
		}
		.accordion_v2 {
			.content-section {
				&:first-child {
					padding-top: 30px;
				}
			}
		}
	}

	@include media('<tabport') {
		.accordion_v2 {
			.content-section,
			.content-padding {
				padding: 0;
			}

			.accordion-trigger {
				.title-tert {
					font-size: 20px;

					&, strong, b {
						font-weight: 500;
					}
				}
			}
		}

		.content-section {
			.content-wrap {
				display: block;
				padding-top: 27px;
				padding-bottom: 25px;

				.block {
					display: block;
					padding-top: 0;
					padding-bottom: 0;
				}

				.video-wrap, .image-wrap {
					margin: 30px 0;
				}

				h3 {
					margin-top: 10px;
				}
			}

			.image-wrap {
				margin: 30px 0 20px;
			}

			.bg-image {
				top: auto;
				left: 0;
				bottom: 0;
				height: 250px;
				z-index: 0;
			}

			&.content-section_v3,
			&.content-section_v4 {
				.accordion-content {
					position: relative;

					.content-wrap {
						padding-bottom: 275px;
						//background: $cararra-2;
                        background: $mine-shaft-2;

					}
				}
			}

			&.content-section_v4 {
				.accordion-content {
					h2 {
						margin: 0 0 17px;
						padding-bottom: 16px;
						font-size: 20px;
						line-height: 24px;
					}
				}
			}
		}
	}

	@include media('<=phoneland') {
		.content-section {
			.content-wrap {
				.video-wrap {
					iframe {
						height: 200px;
					}
				}
			}
		}
	}


	// ADDITIONAL SECTION (BOTTOM)

	.additional-section {
		padding: 90px 0 55px;
		background: #3d3b39 no-repeat center center;
		background-size: cover;

		.section-title,
		.section-text {
			max-width: 510px;
		}

		.section-text {
			margin: 20px 0 30px;
		}

		p {
			color: $white;
		}

		.section-btn {
			margin: 15px 0;

			.btn {
				min-width: 205px;
				text-transform: none;
			}
		}

		@include media('>=tabport') {
			&.has-scroll-btn {
				padding-bottom: 15px;
			}
		}

		@include media('<tabport') {
			padding: 65px 0 45px;

			//.landing-page{
			//#slideMenu5 {
			//	.accordion-content {
			//	padding: 0;
			//	}
			//}

		}

		@include media('<=phoneland') {
			padding: 32px 0 30px;
		}

		.btn-play-wrap{
			//text-align: center;
			position: absolute;
			left: 50%;
			top: 50%;
			//right: 50%;
			z-index: 999;
			a, img{
				z-index: 999999999;
			}
			button{
				background-color: transparent;
				border: none;
			}

		}

	}

	// REGISTRATION SECTION

	.registration-section {
		//padding: 50px 0 35px;
		background: $mine-shaft-2;

		.bounds {
			max-width: 880px;
		}

		.content-wrap {

			.content-block {
				color: $white;

				p {
					color: inherit;
				}

				.section-title {
					padding-bottom: 30px;
					color: inherit;
					border-bottom: 2px dotted $white;

					b, strong {
						font-weight: 500;
					}
				}

				.section-title-release2 {

					opacity: 0.93;
					font-family: $alternative-font;
					font-size: 54px;
					font-weight: bold;
					line-height: 1.19;
					letter-spacing: -0.34px;
					text-align: center;
					color: $white-release2;
					text-transform: uppercase;
					border-bottom: none;

				}
			}

			@include media('>=tabport') {
				.content-block {
					.section-title {
						font-size: 30px;
					}
					p {
						font-size: 17px;
					}

				}
			}

			@include media('>desktop') {
				.content-block {
					padding-right: 28px;
				}
				.form-block {
					padding-left: 28px;
				}
			}
		}

		@include media('<tabport') {
			padding: 12px 0 45px;

			.content-wrap {
				display: block;

				.block {
					display: block;
				}

				.content-block {
					padding-bottom: 35px;

					.section-title {
						padding-bottom: 23px;
						font-size: 24px;
						line-height: 27px;
					}
					.section-title-release2{
						font-size: 34px;
					}
				}

				.form-block {
					padding-top: 35px;
				}
			}
		}

		.button-wrap{
			max-width: 238px;
			height: 63px;
			background-color: #525252;
			display: flex;
			flex: 1 1 auto;
			flex-flow: column;
			align-content: center;
			justify-content: center;
			margin: auto;
			a{
				display: flex;
				flex: 1 1 auto ;
				align-content: center;
				justify-content: center;
				flex-flow: column;

				text-align: center;
				font-family: Roboto;
				font-size: 14px;
				line-height: 2;
				letter-spacing: 7.38px;
				text-align: center;
				background-color: $grayish-yellow;
				color: #000000;
				margin: 5%;
				height: 100%;
				cursor: pointer;


			}

		}
	}


}