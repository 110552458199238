
.pricing-block {
	text-align: center;
	color: $pricing-block-text;
	box-sizing: border-box;
	//padding: 0 32px;



	h2, .block-title {
		margin: 0 0 8px;
        padding: 0;
    	font: normal normal 32px/1 $alternative-font;
    	text-transform: uppercase;
    	text-align: center;
        letter-spacing: -0.5px;
        color: $limed-spruce;
        border: none;
	}

	.block-subtitle {
		max-width: 220px;
		margin: 0 auto 18px;
		font-size: 13px;
		line-height: 16px;
		text-transform: uppercase;
	}

	.block-subtitle-v3{
		font-size: 22px;
		line-height: 1.05;
		text-align: center;
		color: $god-grey;
		margin: 18px 0;
	}

	.block-image {
		margin: 18px 0 10px;

		svg {
			overflow: visible;
			transform: scaleX(1.15);
		}
	}

	p{
		font-size: 16px;
	}

	.number-site{
		font-size: 14px;
		font-weight: bold;
		line-height: 1.07;
		color: $limed-spruce-2;
		margin: 0;

	}
	.for-additional{
		font-size: 14px;
		font-weight: 300;
		line-height: 1.14;
		color: $mine-shaft-2;
		min-height: 32px;
		padding-bottom: 0;

	}

	.price-wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 0 12px;

		.regular-price {
			max-width: 250px;
			width: 100%;
			position: relative;

			.currency {
				padding: 0 3px 18px 0;
				font: 400 20px/23px $secondary-font;
				color: $mine-shaft-3;
			}

			.price {
				padding: 0 12px;
				font: 300 56px/72px $secondary-font;
				color: $mine-shaft-3;

			}

			.price-text-box {
				display: inline-block;
				.text {
					padding-bottom: 10px;
					font-size: 14px;
					line-height: 18px;
					color: $limed-spruce-2;
					text-align: left;
				}
			}
		}

		.discount-price {
			display: none;
			max-width: 250px;
			width: 100%;
			position: relative;

			.discount-currency-box{
				display: inline-block;
				.discount-price-currency {
					font: 500 30px/55px $secondary-font;
					color: $roman;
					position: relative;
					vertical-align: text-bottom;
				}
			}

			.discount-price-value {
				padding: 0 12px;
				font: 500 56px/72px $secondary-font;
				color: $roman;
			}

			.price-text-box {
				display: inline-block;

				.discount-price-text {
					padding-bottom: 10px;
					font-size: 14px;
					line-height: 18px;
					color: $roman;
					text-align: left;
				}
			}

		}


		&.price-wrap_v2 {
			.currency {
				padding-top: 3px;
				padding-bottom: 0;
			}
			.price {
				font-size: 40px;
				line-height: 56px;
			}
		}

		&.has-discount-price {
			flex-direction: column;

			.regular-price {
				max-width: 250px;
				width: 100%;
				position: relative;

				.currency {
					font: 400 15px/23px $secondary-font;
					color: $dusty-grey;
				}

				.price {
					font: 300 40px/72px $secondary-font;
					color: $dusty-grey;

					background-color: transparent;
					background-image: gradient(linear, 19.1% -7.9%, 81% 107.9%, color-stop(0, transparent), color-stop(.48, transparent), color-stop(.5, #000), color-stop(.52, transparent), color-stop(1, transparent));
					background-image: repeating-linear-gradient(163deg, transparent 0%, transparent 48%, #d8271e 50%, transparent 52%, transparent 100%);
				}

				.price-text-box {
					display: inline-block;
					.text {
						font-size: 10px;
						color: $dusty-grey;
					}
				}
			}

			.discount-price{
				display: inline-block;
			}
		}
	}

	.block-text {
		margin: 20px 0;
		font-size: 13px;
		line-height: 17px;
	}

	.block-btn {
		margin: 50px 0 10px;

		.btn {
			min-width: 0;
			width: 100%;
		}
	}

	.container-watch-download .title{
		margin: 5px 0;
	}

	hr{
		margin: 30px 0;
	}
	strong{
		font-size: 16px;
	}

	.teams-plan-wrap{
		border: solid 1px #e4e4c7;
		background-color: rgba(255, 255, 255, 0.67);
		padding: 12px;
		margin-bottom: 15px;
		hr{
			margin: 5px 0;
		}
		.radio-button-wrap{
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			font-size: 13px;

			span{padding-left: 5px;}
			.radio-wrap{
				width: 25px;
				height: 25px;
				border: solid 1px #a2a1a2;
				display: inline-block;
				border-radius: 50%;
				@include media('<=phoneland') {
					padding-top: 2px;
				}

			}
			input[type='radio']:after {
				width: 19px;
				height: 19px;
				border-radius: 15px;
				top: -2px;
				left: -4px;
				position: relative;
				background-color: $white;
				content: '';
				display: inline-block;
				visibility: visible;
				border: 1px solid white;
			}

			input[type='radio']:checked:after {
				width: 19px;
				height: 19px;
				border-radius: 15px;
				top: -2px;
				left: -4px;
				position: relative;
				background-color: #caca89;
				content: '';
				display: inline-block;
				visibility: visible;
				border: 1px solid white;
				@include media('<=phoneland') {
					top: 0px;
					left: -3px;
				}
			}


		}
	}

	.calculate-price-wrap {

		p{
			font-size: 16px;
			color: #000000;
			text-align: left;
			font-weight: 400;
		}

		.quantity-value-wrap{
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			span{
				font-size: 14px;
				font-weight: 300;
			}

			.input-wrap{
				display: flex;
				flex-direction: row;
				font-weight: 500;
				i{
					margin-right: -12px;
				}
				input{
					padding-left: 15px;
					font-weight: 500;
				}
			}
			input{
				padding: 2px;

				&:before{
					display: block;
					//background-color: #f8f8f5;
					content: "AA";
					position: absolute;
					//background-image: url("./../img/icons/tick-sign.svg");
					//width: 19px;
					//height: 16px;
					margin-left: -30px;

				}
			}

			.quantity-wrap{
				max-width: 57px;
			}
			.value-wrap{
				max-width: 75px;
			}
		}
		.disabled{
			opacity: 0.5;
		}

	}



	@include media('>=tabport') {
		.block-btn {
			.btn {
				padding: 7px 15px 5px;
				font-size: 12px;
				letter-spacing: 6.3px;
			}
		}

		.price-info-wrap{
			min-height: 790px;
		}
	}

	@media only screen and (max-width: 1226px) {
		padding: 0;
		.price-info-wrap {
			min-height: 980px;
		}
	}

	@include media('<tabport') {
		max-width: 350px;
		margin: 30px auto;
		padding: 24px 24px 28px;
		background: $pricing-block-bg;
		box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.05);

		.price-info-wrap{
			min-height: auto;
		}

		.price-wrap {
			margin: 15px 0;
		}

		.block-btn {
			.btn {
				background: transparent;

				&:hover {
					background: $a_btn-hover-background;
				}
			}
		}

		&.pricing-block_v2 {
			background: $pricing-block2-bg;
			box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.2);
		}


	}

}

.pricing .pricing-section{
	.content-padding {
		padding: 0 25px;
	}
}