
// Login Form

.small-form {
	width: 378px;
	margin: 0 auto;
	background: $form-body-bg;

	.form-title {
		padding: 23px 40px 22px;
		font: 300 24px/34px $secondary-font;
		text-align: left;
		letter-spacing: -0.5px;
		color: $form-title;
		background: $form-header-bg;
		box-sizing: border-box;
	}

	.form {
		padding: 37px 42px 33px;

		.link-wrap {
			margin-bottom: 20px;
			color: $body-text;

			a {
				font-size: 12px;
				font-weight: 300;
				line-height: 1.42;
				letter-spacing: 0.2px;
				color: $link-secondary;

				&:hover {
					color: $hover-secondary;
				}
			}
		}

		.form-submit {
			text-align: center;

		.text{
			margin: 10px 0;
			font-size: 14px;
		}

		}
	}

	@include media('<=phoneland') {
		width: 260px;

		.form-title {
			padding: 20px 18px 10px;
			font-size: 20px;
			line-height: 24px;
		}

		.form {
			padding: 33px 18px 20px;

			.link-wrap {
				margin-bottom: 18px;
			}
		}
	}

	&.login-form {
		margin-bottom: 20px;
		box-shadow: 0 3px 15px #000;

		.form-field:last-child {
		    margin-bottom: 12px;
		}

		.form {

			.link-wrap {
				text-align: right;

				a {
					text-decoration: underline;
				}
			}

			.form-submit {
				.submit-btn {
					min-width: 0;
					width: 100%;
				}
			}
		}
	}
}



// Registration Form

.registration-form {
	max-width: 370px;
	width: 100%;
	margin: 0;
	background: $form2-body-bg;

	.form-header {
		position: relative;
		min-height: 122px;
		padding: 37px 100px 9px 40px;
		background: $form2-header-bg;
		box-sizing: border-box;

		.info-card {
			position: absolute;
			top: -56px;
			right: -70px;
			width: 152px;
			height: 152px;
			overflow: hidden;
			border-radius: 50%;
			box-shadow: 0 0 15px rgba(0,0,0,0.6);
			transform: rotate(5deg);
			z-index: 2;

			.half {
				height: 50%;
				text-align: center;
				overflow: hidden;
				box-sizing: border-box;
			}

			.top-half {
				padding: 26px 18px 7px;
				background: $form2-header-bg;
			}

			.bottom-half {
				color: $white;
				background: $mine-shaft-2;

				.text1 {
					margin: 8px 0 1px;
					font: normal normal 35px/1 $alternative-font;
					text-transform: uppercase;
					letter-spacing: -0.5px;
				}

				.text2 {
					font: 300 14px/18px $secondary-font;
				}
			}
		}
	}

	.form {
		padding: 24px 42px 36px;

		.additional-block {
			position: relative;
			display: flex;
			align-items: flex-start;

			.text {
				font-size: 13px;
				line-height: 17px;
				color: $body-text;
			}
		}

		.checkbox-wrap {
			margin-right: 10px;

			input[type="checkbox"] {
				position: absolute;
				width: 0;
				height: 0;
				opacity: 0;
				z-index: -3;

				&:checked {
					& ~ label {
						&::before {
							opacity: 1;
						}
					}
				}
			}

			label {
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				top: auto;
				left: auto;
				width: 15px;
				height: 15px;
				margin: 0;
				padding: 1px;
				text-align: center;
				color: $body-text;
				background: transparent;
				border: 1px solid $body-text;
				border-radius: 0;
				pointer-events: auto;
				cursor: pointer;
				box-sizing: border-box;

				&::before {
					position: absolute;
					display: block;
					content: "";
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					opacity: 0;
					background: url("../img/icons/checkmark.svg") no-repeat center center;
					background-size: contain;
					transition: all 0.15s ease-out;
				}
			}
		}

		.form-submit {
			margin: 25px 0 0;
			text-align: right;

			.btn {
				min-width: 0;
				padding-left: 22px;
				padding-right: 16px;
				background: transparent;

				&:hover {
					background: $a_btn-hover-background;
				}
			}
		}
	}

	@include media('<wide-tab') {
		.form-header {
			padding-right: 120px;

			.info-card {
				top: -55px;
				right: -10px;
				width: 122px;
				height: 122px;
				box-shadow: 0 0 10px rgba(0,0,0,0.5);

				.top-half {
					padding: 20px 13px 10px;
				}
				.bottom-half {
					.text1 {
						margin: 6px 0 0;
						font-size: 28px;
					}

					.text2 {
						font-size: 12px;
						line-height: 14px;
					}
				}
			}
		}
	}

	@include media('<tabport') {
		margin: 0 auto;
	}

	@include media('<=phoneland') {
		max-width: none;

		.form-header {
			min-height: 0;
			padding: 30px 130px 7px 20px;
		}

		.form {
			padding: 25px 20px 25px;

			.additional-block {
				align-items: center;
			}

			.checkbox-wrap {
				label {
					width: 20px;
					height: 20px;
				}
			}

			.form-submit {
				margin-top: 30px;
				text-align: center;
			}
		}
	}
}

#banner-employee-registration-form{
	display: none;
	width: 100%;
	//@include media('<=phoneland') {
	//	min-width: 200px;
	//}
}

#banner-employee-video-wrap{
	display: none;
	width: 100%;

	//@include media('<=phoneland') {
	//	min-width: 200px;
	//}

	div{
		margin-top: 7px;
	}
}

.watch-download-wrap{
	max-width: 370px;
	margin-left: auto;
}

.registration-form-v2{
	.form-header {
		background-color: #262d3a;
		font-family: Roboto;
		font-size: 30px;
		font-weight: 300;
		font-style: italic;
		font-stretch: normal;
		line-height: 1.13;
		letter-spacing: -0.64px;
		color: $cararra-3;
		padding: 36px 4px 32px 32px;

		@include media('<tabport') {
			font-size: 20px;
		}


	}
}
// Feedback Form

.feedback-form {
	.block-group {
		flex-wrap: nowrap;

		.form-block {
			display: flex;
			flex-direction: column;
			flex: 1 1 50%;
			max-width: 50%;

			.block-content {
				position: relative;
				flex: 1 1 auto;
				padding: 37px 25px 25px;
				border: 1px solid $alto;
				box-sizing: border-box;

				.bookmarkme {
					top: 37px;
					right: 25px;
				}

				h4 {
					margin: 0 0 25px;
					//padding: 0 110px 12px 0;
					padding: 0 0 12px 0;
					text-transform: uppercase;
					border-bottom: 1px solid $default-border;
					max-width: 500px;
					position: relative;
					width: 100%;

					.add-to-favorites-link{
						font-family: $primary-font;
						font-size: 12px;
						font-weight: 300;
						line-height: 1.8;
						letter-spacing: 0.22px;
						color: #1a1a1a;
						white-space: nowrap;
						float: right;
						margin-left: 10px;
						cursor: pointer;
						text-transform: none;
					}
				}

				@include media('>=tabport') {
					p {
						font-size: 16px;
						line-height: 22px;
					}
				}

				.form-field {
					margin: 38px 0 0;
				}

				.input-wrap {
					display: flex;
					align-items: center;
					height: 115px;

					.number {
						flex: none;
						font-size: 17px;
						line-height: 22px;
					}

					.input {
						flex: 1 1 auto;
					}
				}

				textarea {
					min-height: 115px;
					padding: 8px 16px;
					border: 1px solid $alto;
					resize: vertical;
				}
			}
		}
	}

	.line {
		margin: 32px 32px 24px;
		border-color: $default-border;
	}

	.form-submit {
		padding-top: 2px;
		padding-bottom: 5px;
	}

	@include media('<tabport') {
		.line {
			display: none;
		}

		.block-group {
			padding-top: 0;

			&, .block {
				display: block;
				flex: none;
				max-width: none;
			}
		}
	}

	@include media('<=phoneland') {
		.block-group {

			&, .block {
				padding-right: 0;
				padding-left: 0;
			}

			.form-block {
				.block-content {
					flex: none;
					padding: 40px 16px;
					border: none;

					.bookmarkme {
						top: auto;
						right: 16px;
						bottom: 5px;
					}

					h4 {
						margin: 0 0 22px;
						padding: 0;
						border-bottom: none;
					}

					.form-field {
						margin-top: 26px;
					}

					textarea {
						min-height: 190px;
						padding: 8px 12px;
					}
				}

				&:nth-child(odd) {
					background: #f4f4f4;
				}
			}
		}

		.form-submit {
			.btn-wrap {
				margin-top: 0;
				padding-top: 50px;
				border-top: 1px solid $default-border;;
			}
		}
	}
}


// Survey Form

.survey-form {

	.form-body {
		margin: 15px 0 50px;
	}

	p {
		margin-bottom: 8px;
	}

	.form-submit {
		border-top: 1px solid $default-border;

		.btn_a {
			min-width: 235px;
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	.links-wrap,
	.btn-wrap {
		margin: 25px 0;
	}

	.btn-wrap {
		text-align: right;
	}

	.links-wrap {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		.btn {
			flex: 0 1 auto;
		}
	}

	@include media('<tabport') {
		.form-body {
			margin: 28px 0 44px;
		}

		p {
			margin-bottom: 18px;
			font-size: 16px;
			line-height: 20px;
		}

		.form-submit {
			padding-top: 20px;
		}

		.links-wrap,
		.btn-wrap {
			margin: 15px 0;
		}
	}

	@include media('<=phoneland') {
		.btn-wrap {
			text-align: center;
		}

		.form-submit {
			.btn_a {
				min-width: 0;
			}
		}

		.links-wrap {
			.btn {
				width: auto;
				flex: 1 1 40%;

				&:not(:last-child) {
					margin-right: 15px;
				}
			}
		}
	}
}


// Contact Form

.contact-form {

	.form-group {
		.form-field {

			&:last-child {
				margin-bottom: 20px;
			}

			textarea {
				min-height: 10px;
				//min-height: 185px;
			}
		}
	}

	.form-submit {
		.submit-btn  {
			@include media('>phoneland') {
				min-width: 162px;
				padding: 9px 25px 7px 32px;
			}
		}
	}

	@include media('<tabport') {
		.form-group {
			.form-field {
				textarea {
					min-height: 87px;
				}
			}
		}

		.form-submit {
			margin: 55px 0 0;
		}
	}

	@include media('<=phoneland') {
		.form-group {
			.form-field {
				textarea {
					min-height: 0;
					height: 25px;
				}
			}
		}
	}
}


// Profile Forms

.profile-form {
	.form-group {
		.form-field {
			input,
			select,
			textarea {
				padding-left: 178px;
				font-size: 17px;
				font-weight: 400;
				color: $oxford-blue;

				@include media('<=phoneland') {
					padding-left: 140px;
			        font-size: 16px;
			    }
			}

			label {
			    top: 7px;
			    left: 0;
			    max-width: 160px;
			    font-size: 16px;
			    overflow: hidden;
			    text-overflow: ellipsis;
			    white-space: nowrap;
			    pointer-events: auto;

			    @include media('<=phoneland') {
			    	top: 9px;
			    	max-width: 135px;
			        font-size: 14px;
			    }
			}

			&:last-child {
				margin-bottom: 23px;
			}
		}
	}

	//.additional-block {
	//	display: flex;
	//	align-items: center;
	//	padding: 62px 0 23px;
	//	font-size: 14px;
	//	line-height: 18px;
	//	border-bottom: 1px solid $input-border-bottom;
	//
	//	.form-checkbox {
	//		margin-left: 20px;
	//	}
	//}

	.additional-block-v2{
		border-bottom:none;
		padding: 15px 0 0;
		span{
			font-size: 17px;
			font-weight: 300;
			line-height: 1.27;
			letter-spacing: 0.32px;
			color: #1a1a1a;
		}

		.form-checkbox{
			margin-left: 0;
			margin-right: 20px;
		}

		input:checked + .form-checkbox{
			//font-weight: bold;
			background-color: red;
		}
	}

	.form-submit {
		.submit-btn  {
			@include media('>phoneland') {
				min-width: 177px;
				padding: 9px 25px 7px 32px;
			}
		}
	}

	&.personal-info-form {
		.form-submit {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 23px 0;
			font-size: 14px;
			line-height: 18px;

			.submit-btn  {
				flex: none;
			}
		}
	}

	@include media('<=phoneland') {
		margin-top: 12px;

		.additional-block {
			justify-content: space-between;
			padding: 30px 0 12px;
		}

		&.personal-info-form {
			.form-submit {
				display: block;
				padding: 15px 0 35px;

				.text {
					display: block;
					margin-bottom: 45px;
				}
			}
		}

		.btn-wrap {
			margin: 35px 0;
		}
	}
}


// Notes Form

.notes-form {

	&, .flex-col {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
	}

	.form-textarea {
		flex: 1 1 auto;
		margin-bottom: 30px;
	    padding: 17px;
	    border: 1px solid $god-grey;
	    resize: vertical;
	}

	.form-submit {
		flex: none;
		text-align: right;
	}
}




//Sign Up

.container-sign-form{
	.container-sign-form-wrap-img{
		position: absolute;
		z-index: -1;
		position: absolute;
		z-index: -1;
		width: 100%;
		background-image: url(/img/content/bg-landing-page-section-3.jpg);
		height: 490px;
		top: -75px;
	}

	.sign-form-part1, .sign-form-part3{
		margin: 20px;
	}
	.sign-form {
		margin: 76px 0 164px;

		.login-form {
			max-width: 600px;

			h2 {
				font-size: 19px;
				padding-bottom: 20px;
				margin-bottom: 0;
				font-weight: 400;
			}

			.total-order {
				background-color: $cararra-3;
				max-width: 600px;
				padding: 33px;
				margin-bottom: 40px;

				.label {
					font-size: 19px;
					font-weight: 400;
				}

				.value {
					font-size: 28px;
					line-height: 28px;
					margin-bottom: 0;
				}
			}

			hr {
				margin-top: 35px;
			}


		}

		.accordion-content {
			background-color: $white;
			border-left: 1px solid #e2e2e3;
			border-right: 1px solid #e2e2e3;

			.accordion-content, .register-infos_v1 {
				background-color: $cararra-4;
				border: none;
				z-index: 1;
			}

			.accordion-content_v2, .register-infos_v2{
				background-color: #f3f3ed;
				border: none;
				z-index: 2;
			}

			.register-infos-wrap{
				display: flex;
				flex: 1 1 auto;
				justify-content: space-between;
				//flex-direction: row;
				flex-flow: row wrap;
				margin: 20px;

				.register-infos-item{
					display: flex;
					flex-direction: column;
					flex: 1 1 auto;
					box-shadow: 0 5px 9px 0 rgba(0,0,0,0.2);
					padding: 11px;

					.item-title{
						font-size: 12.3px;
						color: $mine-shaft-2;
						font-weight: 500;

					}
					.item-value{
						text-align: center;
						font-size:  30.2px;
						padding-bottom: 20px;
						font-family: $alternative-font;
						color: $limed-spruce-2;
					}
				}
			}
		}

		.reg-details {
			border-bottom: 1px solid #e2e2e3;
		}

		.accordion.accordion_v1 {
			border: none;

			.accordion-item {
				.accordion-trigger .title-tert {
					.title-part-1 {
						span{
							margin-right: 20px;
						}
					}
				}
			}
		}

		.title-wrap {
			width: 100%;
		}

		.content-wrap {
			padding: 30px 0px !important; //remove import after

		}

		.form-submit {
			text-align: right;
		}

		.additional-block {
			position: relative;
			display: flex;
			align-items: flex-start;
			margin: 35px 0;

			.text {
				font-size: 16px;
				line-height: 17px;
				color: $body-text;
			}
		}

		.checkbox-wrap {
			margin-right: 10px;

			input[type="checkbox"] {
				position: absolute;
				width: 0;
				height: 0;
				opacity: 0;
				z-index: -3;

				&:checked {
					& ~ label {
						&::before {
							opacity: 1;
						}
					}
				}
			}

			label {
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				top: auto;
				left: auto;
				width: 15px;
				height: 15px;
				margin: 0;
				padding: 1px;
				text-align: center;
				color: $body-text;
				background: transparent;
				border: 1px solid $body-text;
				border-radius: 0;
				pointer-events: auto;
				cursor: pointer;
				box-sizing: border-box;

				&::before {
					position: absolute;
					display: block;
					content: "";
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					opacity: 0;
					background: url("../img/icons/checkmark.svg") no-repeat center center;
					background-size: contain;
					transition: all 0.15s ease-out;
				}
			}
		}


		@include media('<tabport') {
			.accordion.accordion_v1 .accordion-item {
				h3::after {
					content: '';
				}

				.accordion-trigger .title-tert {
					.title-part-1 {
						display: block;
					}
				}
			}

			.pricing-block{
				margin: auto;
			}
		}

	}

	.section-header-text{
		font-size: 20px;
		line-height: 1.31;
		color: $god-grey;
		text-align: left;
	}

}


.table_header-wrap{
	//h4, div{
	//	display: inline;
	//}
	display: flex;
	flex-direction: row;

	h4{
		min-width: 200px;
	}
}

