
.accordion {
	.accordion-item {
		.accordion-trigger {
			h2.title-tert {
				b, strong {
					font-weight: 500;
				}
			}
		}
	}

	// DEFAULT ACCORDION

	&.accordion_v1 {
		border: 1px solid $alto;
		border-top: none;

		.accordion-item {
			position: relative;
			width: auto;
			margin: 0 -1px 3px;

			&:last-child {
				margin-bottom: 0;
			}

			@include media('>=tabport') {
				&:not(:last-child) {
					.faq-modal & {
						margin-bottom: 2px;
					}
				}
			}	

			@include media('<tabport') {
				margin-bottom: 1px;
			}

			.accordion-trigger {
				position: relative;
				display: flex;
				min-height: 85px;
				padding: 8px 70px 8px 8px;
				opacity: 0.6;
				color: $accordion-title-color;
				background: $accordion-title-bg;
				box-sizing: border-box;
				cursor: pointer;

				.ie10 &,
				.ie11 & {
					min-height: 0;
				}

				.faq-modal & {
					display: block;
					min-height: 0;
				}

				&:hover {
					opacity: 0.8;
				}

				.title-tert {
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					flex: none;
					width: 230px;
					margin: 0;
					padding: 10px 15px;
					color: inherit;
					box-sizing: border-box;

					.title-part-1 {
						margin-right: 5px;
					}

					.title-part-2 {
						font-weight: 700;
					}

					.faq-modal & {
						font-weight: 400;

						&::after {
							display: none;
						}

						@include media('>=tabport') {
							display: block;
							width: auto;
							padding-top: 4px;
							padding-bottom: 4px;
						}	
					}
				}

				.title-wrap {
					width: 100%;
				}

				.content-tert {
					display: flex;
					align-items: center;
					flex: 1 1 auto;
					padding: 10px 30px;
					font-size: 20px;
					line-height: 23px;
					color: inherit;
					border-left: 1px solid $white;
					box-sizing: border-box;
				}

				p {
					margin: 0;
				}

				.accordion-trigger-icon {
					position: absolute;
					display: block;
					flex: none;
					top: 50%;
					right: 25px;
					width: 30px;
					height: 30px;
					margin-top: -15px;
					overflow: hidden;
					background: transparent;
					border-radius: 50%;
					z-index: 1;
					transition: all 0.5s ease-out 0.1s;

					svg {
						position: absolute;
						display: block;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						opacity: 0;
						color: $white;
						transition: all 0.35s ease-out 0.1s;

						&.icon-plus-in-circle {
							opacity: 1;
						}
					}

					/*

					&::before,
					&::after {
						position: absolute;
						display: block;
						content: "";
						background: $accordion-title-bg;
						z-index: 2;
						transition: all 0.35s ease-out 0.1s;
					}

					&::before {
						top: 50%;
						left: 7px;
						right: 7px;
						height: 3px;
						margin-top: -1.5px;
					}

					&::after {
						top: 7px;
						left: 50%;
						bottom: 7px;
						width: 3px;
						margin-left: -1.5px;
					}

					*/
				}

				@include media('<tabport') {
					display: block;
					min-height: 0;
					padding: 0;
					overflow: hidden;

					&, &:hover {
						opacity: 0.85;
					}

					.title-tert {
						position: relative;
						display: block;
						width: auto;
						padding: 18px 80px 18px 32px;
						line-height: 24px;

						.title-part-1 {
							display: none;
						}

						&::after {
							content: ":";
						}
					}

					.content-tert {
						position: relative;
						display: block;
						max-height: 0;
						padding: 0 32px;
						overflow: hidden;
						text-align: center;
						border-left: none;
						transition: max-height 0.6s ease-in;

						&::before,
						&::after {
							position: absolute;
							display: block;
							content: "";
						}

						&::before {
							top: 0;
							left: 0;
							right: 0;
							height: 1px;
							background: $white;
						}

						&::after {
							top: 0;
							left: 50%;
							margin-left: -7px;
							border-top: 9px solid $white;
							border-left: 7px solid transparent;
							border-right: 7px solid transparent;
							border-bottom: 8px solid transparent;
						}

						p {
							padding: 22px 0;
						}
					}

					.accordion-trigger-icon {
						right: 32px;
					}
				}

				@include media('<=phoneland') {
					.title-tert {
						padding-right: 60px;
						padding-left: 16px;
					}
					.content-tert {
						padding: 0 16px;
					}
					.accordion-trigger-icon {
						right: 16px;
					}
				}
			}

			.accordion-content {
				max-height: 0px;
			    overflow: hidden;
			    opacity: 0.5;
				transition: all 0.6s ease-out;

				.content-wrap {
					max-width: 865px;
					width: 100%;
					margin: 0 auto;
					padding: 12px 25px 65px;
					box-sizing: border-box;

					.faq-modal & {
						padding-top: 60px;
						padding-bottom: 50px;
						font-size: 16px;
						line-height: 22px;

						b, strong {
							font-weight: 500;
						}

						ul {
							margin: 0 0 16px;
							padding: 0;
							list-style: none;

							li {
								position: relative;
								padding-left: 30px;

								& + li {
									margin-top: 15px;
								}

								&::before {
									position: absolute;
									display: block;
									content: "";
									top: 7px;
									left: 7px;
									width: 4px;
									height: 4px;
									background: $body-text;
									border-radius: 50%;
								}
							}
						}
					}

					@include media('<tabport')  {
						padding-left: 32px;
						padding-right: 32px;

						.faq-modal & {
							padding-top: 35px;
							padding-bottom: 25px;
						}
					}

					@include media('<=phoneland') {
						padding: 10px 0;

						.faq-modal & {
							padding: 30px 16px 20px;
						}
					} 

					.content-block {
						position: relative;
						display: block;
						padding: 28px 110px 25px 0;
						overflow: hidden;
						border-bottom: 1px solid $alto;
						transition: all 0.25s ease-out;
						cursor: pointer;

						&:hover {
							color: $hover-secondary;

							.content-block-title {
								h4 {
									&, .highlighted, .title-icon {
										color: $hover-secondary;
									}
								}
							}
						}

						@include media('<wide-tab') {
							padding-right: 50px;
						}

						@include media('<=phoneland') {
							display: flex;
							flex-direction: column;
							padding: 35px 16px 30px;
							border-bottom: none;

							&:nth-child(even) {
								background: $cararra-2;
							}
						}

						.content-block-image {
							float: left;
							width: 172px;
							margin-bottom: 10px;
							padding-top: 7px;

							img {
								width: 100%;
							}

							@include media('<=phoneland') {
								float: none;
								width: auto;
								margin-bottom: 28px;
								padding-top: 4px;
								order: 1;
							}
						}

						.content-block-title,
						.content-block-text {
							margin-left: 195px;

							@include media('<=phoneland') {
								margin-left: 0;
							}
						}

						.content-block-icon {
							position: absolute;
							top: 28px;
							right: 0;
							width: 24px;
							height: 24px;
							color: $oxford-blue;

							svg {
								display: block;
								width: 100%;
								height: 100%;
								color: inherit;	
							}

							@include media('<=phoneland') {
								flex: none;
								top: 32px;
								right: 16px;
							}
						}

						.content-block-title {
							h4 {
								position: relative;
								margin: 0 0 23px;
								font: 300 18px/28px $primary-font;
								transition: color 0.25s ease-out;

								&.has-icon {
									padding-left: 42px;
								}

								&:not(.has-icon) {
									.title-icon {
										display: none;
									}
								}

								.title-icon {
									position: absolute;
									display: block;
									top: 2px;
									left: 0;
									color: $oxford-blue;
								}

								.highlighted {
									font-weight: 700;
									color: $oxford-blue;
									transition: color 0.25s ease-out;
								}
							}

							@include media('<tabport') {
								h4 {
									margin-bottom: 20px;

									&.has-icon {
										padding-left: 0;
									}

									.title-icon {
										display: none;
									}
								}
							}

							@include media('<=phoneland') {
								order: 0;

								h4 {
									padding-right: 40px;
									line-height: 23px;

									.highlighted {
										display: block;
									}
								}
							}
						}

						.content-block-text {
							font-size: 16px;
							line-height: 22px;

							p {
								margin-bottom: 10px;
							}

							@include media('<tabport') {
								font-size: 15px;
								line-height: 1.33;
							}

							@include media('<=phoneland') {
								order: 2;
							}
						}

						&.unavailable {
							opacity: 0.4;
							pointer-events: none;
						}
					}
				}

				.group {
					padding-top: 15px;

					.group-title {
						margin: 20px 0 10px;
						padding: 10px 0;
						text-transform: uppercase;
						border-bottom: 1px solid $default-border;
					}

					.content-block {

						.content-block-title {
							margin-bottom: 23px;
							font-size: 17px;
							line-height: 28px;
							color: $body-text;

							.number {
								font-weight: 700;
								color: $oxford-blue;
							}
						}

						&:last-child {
							border-bottom: 0;
						}
					}
				}
			}

			&.accordion-item-open {

				.accordion-trigger {
					opacity: 1;
					background: $accordion-opened-title-bg;

					.accordion-trigger-icon {
						transform: rotate(360deg);

						svg {
							&.icon-plus-in-circle {
								opacity: 0;
							}
							&.icon-minus-in-circle {
								opacity: 1;
							}
						}

						/*

						&::before,
						&::after {
							background: $accordion-opened-title-bg;
						}

						&::after {
							opacity: 0;
						}

						*/
					}

					.faq-modal & {
						overflow: visible;

						&::after {
							position: absolute;
						    display: block;
						    content: "";
						    top: 100%;
						    left: 50%;
						    margin-left: -6px;
						    border-top: 8px solid $accordion-opened-title-bg;
						    border-left: 6px solid transparent;
						    border-right: 6px solid transparent;
						    border-bottom: 7px solid transparent;
						}
					}

					@include media('<tabport') {
						.content-tert {
							max-height: 400px;
						}
					}
				}

				.accordion-content {
	   				max-height: 3500px;
	    			opacity: 1;
	   				transition: max-height 0.6s ease-in, opacity 0.4s ease-out 0.2s;
				}
			}

			&.is_completed {
				.accordion-trigger {
					opacity: 1 !important;
					color: $accordion-completed-title-color;
					background: $accordion-completed-title-bg;

					.accordion-trigger-icon {
						transition: none !important;

						svg {
							&:not(.icon-check-mark) {
								opacity: 0;
							}
							&.icon-check-mark {
								color: inherit;
								opacity: 1;
							}
						}
					}

					@include media('<tabport') {
						background: $thistle-green;
					}
				}
			}
		}
	}


	// ACCORDION - MOBILE ONLY

	&.accordion_v2 {
		.accordion-item {
		
			@include media('>=tabport') {

				.accordion-trigger {
					display: block;
					pointer-events: none;
					cursor: text;

					.accordion-trigger-icon {
						display: none;
					}
				}
				.accordion-main{
					display: none;
					//@include media('<=phoneland') {
					//    display: block;
					//}
				}


				.accordion-content {
					max-height: none;
					overflow: visible;
					opacity: 1;
					transition: none;

					.content-wrap {
						position: relative;
						max-width: none;
						margin: 0 -16px;
						padding: 0;
					}

					.single-col {
						padding: 0;
					}
				}
			}

			@include media('<tabport') {
				margin-bottom: 1px;

				.accordion-trigger {
					position: relative;
					display: block;
					padding: 0;
					overflow: hidden;
					opacity: 0.6;
					color: $accordion-title-color;
					background: $accordion-title-bg;
					box-sizing: border-box;
					cursor: pointer;

					&, &:hover {
						opacity: 0.85;
					}

					.title-tert {
						position: relative;
						flex: none;
						margin: 0;
						padding: 18px 80px 18px 32px;
						line-height: 24px;
						color: inherit;
						box-sizing: border-box;
						text-transform: uppercase;
					}

					p {
						margin: 0;
					}

					.accordion-trigger-icon {
						position: absolute;
						display: block;
						top: 50%;
						right: 32px;
						width: 30px;
						height: 30px;
						margin-top: -15px;
						overflow: hidden;
						background: transparent;
						border-radius: 50%;
						z-index: 1;
						transition: all 0.5s ease-out 0.1s;

						svg {
							position: absolute;
							display: block;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							opacity: 0;
							color: $white;
							transition: all 0.35s ease-out 0.1s;

							&.icon-plus-in-circle {
								opacity: 1;
							}
						}
					}					
				}

				.accordion-content {
					max-height: 0px;
				    overflow: hidden;
				    opacity: 0.5;
					transition: all 0.6s ease-out;
				}

				&.accordion-item-open {
					.accordion-trigger {
						opacity: 1;
						background: $accordion-opened-title-bg;

						.accordion-trigger-icon {
							transform: rotate(360deg);

							svg {
								&.icon-plus-in-circle {
									opacity: 0;
								}
								&.icon-minus-in-circle {
									opacity: 1;
								}
							}
						}
					}

					.accordion-content {
		   				max-height: 3500px;
		    			opacity: 1;
		   				transition: max-height 0.6s ease-in, opacity 0.4s ease-out 0.2s;
					}
				}
			}

			@include media('<=phoneland') {
				.accordion-trigger {
					.title-tert {
						padding-right: 60px;
						padding-left: 16px;
					}
					.content-tert {
						padding: 0 16px;
					}
					.accordion-trigger-icon {
						right: 16px;
					}
				}		
			}
		}
	}


}


// FIXES FOR FIREFOX (very strange bug with svg + opacity)

@-moz-document url-prefix() {

    .accordion.accordion_v1 {
    	.accordion-item {

    		.accordion-trigger {
	    		.accordion-trigger-icon {
	    			border-radius: none !important;

					svg {
			    		opacity: 1 !important;

			    		use {
			    			opacity: 0;
			    		}

			    		&.icon-plus-in-circle {
			    			use {
			    				opacity: 1;
			    			}
						}
			    	}
	    		}
	    	}

	    	&.accordion-item-open {
				.accordion-trigger {
					.accordion-trigger-icon {
						svg {
							&.icon-plus-in-circle {
								use {
									opacity: 0;
								}
							}
							&.icon-minus-in-circle {
								use {
									opacity: 1;
								}
								
							}
						}
					}
				}
	    	}

	    	&.is_completed {
				.accordion-trigger {
					.accordion-trigger-icon {
						transition: all 0.5s ease-out 0.1s !important;
					}
				}
			}
    	}
    }
}