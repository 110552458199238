
// TABLE - STYLE 1

.table_v1-container {
	padding: 27px 37px 48px;
	background: $wild-sand;

	h4 {
		margin: 0 0 13px;
		font: 500 20px/1.7 $primary-font;
	}

	.table-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 13px;

		h4 {
			margin: 0;
		}

		.table-info {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			font-size: 14px;
			line-height: 18px;

			b {
				font-weight: 500;
			}

			p {
				margin: 0;
			}
		}

		.block-btn {
			display: inline-block;
			flex: none;
			margin-left: 10px;
			color: $oxford-blue;

			svg {
				display: block;
			}
		}
	}

	.table_v1-wrap {
		padding: 24px 40px 32px;
		background: $white;
		border: 1px solid $alto;
	}

	@include media('<wide-tab') {
		padding: 40px 0 25px;
		background: transparent;
	}

	@include media('<tabport') {
		h4 {
			margin-bottom: 15px;
			font-size: 18px;
			line-height: 30px;
		}

		.table_v1-wrap  {
			padding: 4px 14px 20px;
		}
	}

	@include media('<=phoneland') {
		.table-header {
			display: block;

			.table-info {
				justify-content: space-between;
				margin-top: 10px;
			}
		}
	}
}

.table_v1 {
	width: 100%;

	thead {
		padding-bottom: 15px;
		border-bottom: 1px solid $default-border;
	}

	tr {
		td, th {
			padding: 10px;
			text-align: left;
			box-sizing: border-box;

			@include media('>=wide-tab') {
				&:first-child {
					width: 25%;
				}

				&:last-child {
					width: 20%;
				}
			}

			@include media('>=tabport') {
				&:first-child {
					padding-left: 0;
				}

				&.btn-parent {
					text-align: right;
				}
			}
		}

		th {
			font: 700 18px/22px $primary-font;
			text-transform: uppercase;
			color: $mine-shaft-2;
		}

		td {
			font: 300 17px/22px $primary-font;
			color: $body-text;

			a {
				font-weight: 400;
				text-decoration: underline;
				color: $astral;

				&:hover {
					color: $hover-primary;
				}
			}

			.table-btn {
				display: inline-block;
				text-decoration: none;
				vertical-align: middle;
				color: $dusty-grey;

				svg {
					display: block;
				}

				&:hover {
					color: $hover-primary;
				}
			}
		}
	}

	tbody {
		tr {
			&:first-child {			
				@include media('>=tabport') {
					td {
						padding-top: 25px;
					}
				}
			}
		}
	}

	@include media('<tabport') {
		thead {
			display: none;
		}

		table, tbody, tfoot, tr, td {
			display: block;
		}

		tr {
			padding: 16px 0 20px;

			&:not(:first-child) {
				border-top: 1px solid $default-border;
			}

			td, th {
				display: flex;
				padding: 4px 0;
				font-size: 15px;

				&::before {
					position: relative;
					display: inline-block;
					flex: 0 0 auto;
				    content: attr(data-label);
				    width: 107px;
				    padding-right: 7px;
				    font-weight: 700;
				    font-size: inherit;
				    line-height: inherit;
				    text-transform: uppercase;
				    color: $mine-shaft-2;
				    box-sizing: border-box;
				}
			}
		}
	}
}


// TABLE - STYLE 2

.table_v2-wrap {
	margin: 40px 0 5px;
}

.table_v2 {
	width: 100%;
	border-collapse: separate;
	border-spacing: 3px;

	tr {
		td {
			padding: 13px;
			font: 300 17px/22px $primary-font;
			vertical-align: top;
			border: 1px solid rgba(155,155,155,0.4);
			box-sizing: border-box;

			b, strong {
				font-weight: 500;
			}

			&.score {
				min-width: 55px;
				font-weight: 500;
				text-align: center;
			}

			&.icon {
				width: 40px;
				padding: 13px 6px;
				text-align: right;
				opacity: 0;
				color: $mandy;
				border: none;

				svg {
					display: inline-block;
					vertical-align: middle;
				}
			}
		}

		&.highlighted {
			td {
				&.icon {
					opacity: 1;
				}

				&:not(.icon) {
					color: $white;
					background: $oxford-blue;
					border-color: $oxford-blue;
					transition: all 0.3s ease-out;
					z-index: 3;
				}
			}
		}
	}

	@include media('<=phoneland') {
		display: block;

		tbody {
			position: relative;
			display: flex;
			padding-bottom: 96px;

			tr {
				display: flex;
				flex-direction: column;
				flex: 1 1 15%;

				td {
					padding: 14px 6px;
					font-size: 15px;
					line-height: 20px;

					&.score {
						min-width: 0;
						height: 50px;
					}

					&.icon {
						width: auto;
						height: 32px;
						padding: 6px;
						text-align: center;

						svg {
							transform: rotate(90deg);
						}
					}

					&.text {
						position: absolute;
						flex: none;
						top: 81px;
						left: 0;
						right: 0;
						height: 96px;
						padding: 16px;
						overflow: hidden;
						text-overflow: ellipsis;
						background: $white;
					}
				}

				& + tr {
					td:not(.text) {
						border-left: none;
					}
				}
			}
		}
	}

}


.first-cell{

	span{
		width: 46%;
		font-size: 15px;
		font-weight: 500;
		line-height: 1.33;
		color: #20262a;
	}
	span:first-child{
		margin-right: 6px;
	}

}

.second-cell, .third-cell{
	width: 27%;
	font-size: 16px;
	font-weight: 300;
	line-height: 1.25;
	color: #20262a;
}

.td-date{
	max-width: 20px!important;
	width: 5%!important;
}

.td-view{
	display: flex;
	flex-direction: row;
	justify-content: space-between;

}

