
.dashboard {

  .main {
    padding: 65px 0 50px;

    @include media('<desktop') {
      padding-top: 40px;
    }
    @include media('<tabport') {
      padding-top: 23px;
    }
  }

  .top-section {
    padding: 0 0 30px;

    .section-header {
      font-size: 20px;
      line-height: 1.5;

      h2, .section-title {
        margin: 20px 0 12px;
        padding-bottom: 22px;
      }

      p {
        margin: 0;

        .name {
          font-weight: 700;
        }
      }
    }

    .section-body {
      padding-top: 0;

      .block {
        padding-top: 12px;
      }
    }

    @include media('<tabport') {
      padding-bottom: 18px;

      .section-header {
        font-size: 16px;
        line-height: 1.25;

        h2, .section-title {
          margin: 0 0 12px;
          padding-bottom: 8px;
        }
      }

      .section-body {
        margin-top: 35px;

        &, .block {
          display: block;
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }


  .hello-block{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .container-change-user{
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 32px;
      font-weight: 300;
      line-height: 1.06;
      color: $god-grey;

      .box-text{
        //width: 100%;
        margin-right: 10px;
        font-weight: 400;

      }


      .custom-select{
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        .option-selected{
          display: none;
        }
        .option-selected[selected=selected] {
          display: block;

        }

        span{
          margin-left: 15px;
          display: flex;
          align-items: center;
        }

      }

    }

    .container-select{
      display: none;
      flex-direction: column;
      position: absolute;
      //top:100px;
      top:70%;
      //margin-top: 80px;
      padding: 25px 30px;
      background-color: $white;
      z-index: 999;
      box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
      max-width: 508px;
      width: 100%;
      margin-right: 15px;

      a{
        font-size: 32px;
        font-weight: 300;
        line-height: 1.31;
        color: #3c3c3b;
        //margin-bottom: 5px;
        &:hover{
          color: #b8b898;
        }

      }
    }
  }

  .block-info-dash{
    background-color: #f2f2ea;
      margin: 0 32px;

    @include media('<=phoneland') {
      margin: 0 16px;
    }
  }
  .block-info-dash-0{
    background-color: #f2f2ea;
    margin: 0 auto;

    @include media('<=phoneland') {
      margin: 0 auto;
    }
  }
  .video-day-block{
    display: flex;
    padding: 16px 0;
    margin-top: 40px;

    .tooltip-wrap{
        width: 24px;
        margin-right: 26px;
        margin-left: 22px;
        margin-top: 10px;
      .icon-info {
        width: 25px;
        height: 25px;
      }
    }
  }
  .video-of-day-footer-top{
    font-family: Yantramanav;
    font-size: 16px;
    margin-right: 15px;
  }
  .video-of-day-footer-bottom{
    font-family: Yantramanav;
    font-size: 15px;
    margin-right: 15px;
  }
  .info-box{
    border: 1px solid #b8b898;
    display: flex;
    border-radius: 40px;


    .info-box-text{
      font-size: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 30px;
    }

    .info-box-text-v2{
      justify-content: center;
      padding: 0px 25px;
      min-width: 203px;
    }

    .info-box-day{

      border-radius: 50%;
      background-color: #b8b898;
      width: 80px;
      height: 72px;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: -3px;
    }

    .info-box-day-text{
      font-size: 13px;
    }

    .info-box-day-value{
      //font-family: Yantramanav-Regular;
      font-size: 28px;
      line-height: 0.84;
    }

  }

.motivation-text{
  padding-right: 20px;
}
  .header-static{
    margin: 30px 0 60px;
  }
  .table_v1-container-v2{
    padding: 21px;
  }

  .line-padding{
    margin: 50px 0;
  }

  .cell-inline{
    display: flex;
    flex-direction: row;

    span:first-child{
      margin-right: 25px;
    }

  }
}

.tutorial{

  padding-bottom: 100px;
  h1{
    color: grey;
  }


  hr{
    margin: 50px 0;
  }

  .content-section_v2{
    padding: 50px 0;
    background: #f8f8f5;
  }

  .padding-top{
    padding: 50px 0;
  }
}

.icon-right-chevron{
  width: 15px;
}